import React from "react";

import { Col, Row } from "reactstrap";
import { workflowStepTypes } from "../../../../../constants/workflowSteps";
import {
  ExecutionCameraStep,
  ExecutionChecklistStep,
  ExecutionFormStep,
  ExecutionGeolocationStep,
  ExecutionLicensePlateStep,
  ExecutionQrStep,
  ExecutionSignatureStep,
  ExecutionStep,
} from "../../../../../types/WorkflowExecution";

import FormStepData from "./Form";
import LicensePlateStepData from "./LicensePlate";
import ImagesStepData from "./Images";
import QrStepData from "./Qr";
import { IImage } from "../../../../../types/Image";
import ChecklistStepData from "./Checklist";
import Geolocation from "./Geolocation";

interface IExecutionDataProps {
  step: ExecutionStep;
}

const ExecutionData: React.FC<IExecutionDataProps> = ({ step }) => {
  switch (step.type) {
    case workflowStepTypes.LicensePlate:
      return <LicensePlateStepData value={(step as ExecutionLicensePlateStep).value} />;
    case workflowStepTypes.Form:
      return (
        <Row>
          <Col>
            <FormStepData fields={(step as ExecutionFormStep).fields} />
          </Col>
        </Row>
      );
    case workflowStepTypes.Camera:
      return <ImagesStepData images={(step as ExecutionCameraStep).images} />;
    case workflowStepTypes.Signature:
      return (
        <ImagesStepData
          images={
            (step as ExecutionSignatureStep).signature
              ? [(step as ExecutionSignatureStep).signature as IImage]
              : []
          }
        />
      );
    case workflowStepTypes.Qr:
      return <QrStepData values={(step as ExecutionQrStep).values} />;
    case workflowStepTypes.Checklist:
      return <ChecklistStepData items={(step as ExecutionChecklistStep).items} />;
    case workflowStepTypes.Geolocation:
      return <Geolocation values={(step as ExecutionGeolocationStep).values} />;
    default:
      return <div>Undefined data formatter</div>;
  }
};

export default ExecutionData;
