import moment from "moment";
import * as types from "../../actions/types";

const initialPaginationState = {
  page: 1,
  totalPages: 0,
  limit: 20,
  totalDocs: 0,
  pagingCounter: 0,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null,
  timestamp: moment().unix()
};
export const paginationReducerFor = prefix => {
  const paginationReducer = (state = initialPaginationState, action) => {
    const { type, payload } = action;
    switch (type) {
      case prefix + types.SET_PAGINATION:
        return { ...state, ...payload };
      default:
        return state;
    }
  };
  return paginationReducer;
};
