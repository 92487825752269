import React, { useState, useEffect, useCallback } from "react";

// utils
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios from "axios";

//Redux
import { useAppDispatch } from "../../../hooks/redux";
import { APPROVE_DIALOG_MODAL } from "../../../modals/types";

// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";

import {
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  Button,
  CardFooter,
} from "reactstrap";
// import LoadingList from "./../LoadingList";
import { ListGroupItem } from "reactstrap/lib";
import EditableText from "../../../components/EditableText";
import ActionItems, { ActionItemTypes, Triggers } from "../../../components/ActionItems";

// Parts
import ListGroup from "reactstrap/lib/ListGroup";
import { IGeolocation, IGeolocationBase, IGeolocationColleciton } from "../../../types/Geolocation";
import { QRCodeCanvas } from "qrcode.react";
import LabelFormGroup from "../../../components/Inputs/LabelFormGroup";
import SpinnerButton from "../../../components/Buttons/SpinnerButton";
import { useDebouncedCallback } from "use-debounce";
import { pdf } from "@react-pdf/renderer";
import QRCodeCollectionPdf from "../../../exports/qrPdf";
import { formatDate } from "../../../helpers/date";
import moment from "moment";
import { safeString } from "../../../helpers/text";
import { saveAs } from "file-saver";
import LoadingOverlay from "../../../components/LoadingOverlay";
import BootstrapTable from "react-bootstrap-table-next";
import MODALS from "../../../constants/modals";

import { Icon } from "leaflet";

import { Map, TileLayer, Marker, Popup, CircleMarker, Circle, Tooltip } from "react-leaflet";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useFormik } from "formik";
import PermissionGuard from "../../../components/PermissionGuard";

import geolocationSchema from "../../../schemas/geolocation";
import { set } from "lodash";
import { openModal } from "../../../actions/modals";

interface IQrCollectionProps {
  geolocationCollectionId: string;
}

const GeolocationCollection: React.FunctionComponent = () => {
  const { geolocationCollectionId } = useParams<IQrCollectionProps>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [selected, setSelected] = useState<IGeolocation | IGeolocationBase>();

  const [isDeleting, setDeleting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IGeolocationColleciton>();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    touched,
  } = useFormik({
    initialValues: {
      name: selected ? selected.name : "",
      latitude: selected ? selected.latitude : 0,
      longitude: selected ? selected.longitude : 0,
      radius: selected ? selected.radius : 0,
    },
    validationSchema: geolocationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .put(`/api/classifiers/geolocations/${geolocationCollectionId}/geolocations`, {
          _id: selected && "_id" in selected ? selected._id : undefined,
          ...values,
        })
        .then((result) => {
          loadData();
          setSelected(undefined);
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          console.log("err:", err);
        });
    },
  });

  const onSubmitCallback = (value = "1") => {
    const values = {
      _id: geolocationCollectionId,
      name: value,
    };
    axios.put(`/api/classifiers/geolocations/${geolocationCollectionId}`, values).catch((err) => {
      console.log("err:", err);
    });
  };

  const onCollectionDelete = useCallback(() => {
    axios
      .delete(`/api/classifiers/geolocations/${geolocationCollectionId}`)
      .then((result) => {
        history.push("/geolocation");
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [geolocationCollectionId]);

  const onGeolocationDelete = useCallback(
    (id: string) => {
      setDeleting(true);
      axios
        .delete<IGeolocationColleciton>(
          `/api/classifiers/geolocations/${geolocationCollectionId}/geolocations/${id}`
        )
        .then((result) => {
          setData(result.data);
          setSelected(undefined);
          setDeleting(false);
        })
        .catch((err) => {
          setDeleting(false);
          console.log("err:", err);
        });
    },
    [geolocationCollectionId]
  );

  const loadData = () => {
    setLoading(true);
    axios
      .get<IGeolocationColleciton>(`/api/classifiers/geolocations/${geolocationCollectionId}`)
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  };

  useEffect(() => {
    loadData();
  }, [geolocationCollectionId]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <div className="d-flex align-items-center">
              <i className="fas fa-map-marker-alt mt-1" />
              <EditableText value={data ? data.name : ""} onSubmit={onSubmitCallback} />
            </div>
          </>
        }
        button={
          <ActionItems
            elements={[
              {
                icon: "trash",
                tooltip: t("buttons.delete"),
                type: ActionItemTypes.Button,
                click: () => {
                  dispatch(
                    openModal(APPROVE_DIALOG_MODAL, {
                      title: t("modalTitles.approveDialog"),
                      message: t("messages.approveGeolocationCollectionDelete", {
                        name: data && data.name,
                      }),
                      onSuccessCallback: onCollectionDelete,
                    })
                  );
                },
              },
            ]}
            count={0}
            trigger={Triggers.Click}
            icon={<i className="fas fa-cog" />}
            link={false}
          />
        }
      />

      <Container className="mt--6" fluid>
        <PanelGroup
          autoSaveId="gelocations"
          direction="horizontal"
          style={{
            minHeight: 400,
            flex: 1,
          }}
        >
          <Panel
            defaultSize={80}
            style={{
              display: "flex",
            }}
          >
            <Map
              center={[56.981506309089326, 24.393194314764013]}
              zoom={8}
              scrollWheelZoom={true}
              style={{ width: "100%", height: "calc(100vh - 260px)", minHeight: 500 }}
              onclick={(e) => {
                if (selected) {
                  setFieldValue("latitude", e.latlng.lat);
                  setFieldValue("longitude", e.latlng.lng);
                } else {
                  setSelected({
                    name: "",
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng,
                    radius: 50,
                  });
                }
                console.log(e);
              }}
            >
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
              <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
              {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
              {selected && (
                <Circle
                  center={[values.latitude, values.longitude]}
                  radius={values.radius}
                  color="#5CB85C"
                />
              )}
              {data && (
                <>
                  {data.locations.map((location) => {
                    if (selected && "_id" in selected && selected._id === location._id) {
                      return null;
                    }

                    return (
                      <Circle
                        key={location._id}
                        center={[location.latitude, location.longitude]}
                        radius={location.radius}
                        color="#E74C3C"
                        bubblingMouseEvents={false}
                        onclick={
                          !selected
                            ? () => {
                                setSelected(location);
                              }
                            : undefined
                        }
                      >
                        <Tooltip>{location.name}</Tooltip>
                      </Circle>
                    );
                  })}
                </>
              )}
            </Map>
          </Panel>

          {selected && (
            <PanelResizeHandle
              style={{
                position: "relative",
                width: 8,
                marginLeft: 8,
                marginRight: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 4,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <i
                  className="fas fa-grip-vertical"
                  style={{
                    fontSize: 12,
                  }}
                />
              </div>
            </PanelResizeHandle>
          )}

          {selected && (
            <Panel
              style={{
                display: "flex",
              }}
            >
              <Container
                style={{
                  padding: 0,
                }}
              >
                <Row>
                  <Col xs>
                    <LabelFormGroup
                      name="name"
                      label={t("fields.name")}
                      value={values.name}
                      onChange={handleChange}
                      touched={touched.name}
                      validationErrors={errors.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <LabelFormGroup
                      name="latitude"
                      label={t("fields.latitude")}
                      value={values.latitude}
                      onChange={handleChange}
                      touched={touched.latitude}
                      validationErrors={errors.latitude}
                    />
                  </Col>
                  <Col xs={6}>
                    <LabelFormGroup
                      name="longitude"
                      label={t("fields.longitude")}
                      value={values.longitude}
                      onChange={handleChange}
                      touched={touched.longitude}
                      validationErrors={errors.longitude}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <LabelFormGroup
                      name="radius"
                      label={t("fields.radius")}
                      value={values.radius}
                      onChange={handleChange}
                      touched={touched.radius}
                      validationErrors={errors.radius}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {"_id" in selected && (
                      <SpinnerButton
                        isLoading={false}
                        // disabled={!hasAccess}
                        onClick={() => {
                          onGeolocationDelete(selected._id);
                        }}
                        className="ml-auto"
                        color="danger"
                      >
                        {t("buttons.delete")}
                      </SpinnerButton>
                    )}
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {
                        setSelected(undefined);
                      }}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <SpinnerButton
                      isLoading={isSubmitting}
                      // disabled={!hasAccess}
                      onClick={() => handleSubmit()}
                      className="ml-auto"
                      color="primary"
                    >
                      {"_id" in selected ? t("buttons.save") : t("buttons.create")}
                    </SpinnerButton>
                  </Col>
                </Row>
              </Container>
            </Panel>
          )}
        </PanelGroup>
      </Container>
    </>
  );
};

export default GeolocationCollection;
