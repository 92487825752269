import { t } from "../helpers/common";

export default {
  0: t("errorCodes.unhandleddError", "Unhandled error occured"),
  1: t("errorCodes.roleStillAssignedToUser", "Role is still assigned to some user"),
  2: t("errorCodes.initialPassword", "Initial password didn't match"),
  3: t(
    "errorCodes.qrCollectionStllAssignedToWorkflow",
    "Qr code collection is still assigned to some workflow steps"
  ),
};
