import React, { useState } from "react";

// utils
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useDebouncedCallback } from "use-debounce";

// components
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

interface IProps {
  onSearch: (text: string) => void;
}

export default function ListViewHeader(props: IProps) {
  const { onSearch } = props;

  const { t } = useTranslation();

  const [isSearchFocused, setSearchFocused] = useState<boolean>(false);

  const searchDebounced = useDebouncedCallback((value: string) => {
    onSearch(value);
  }, 1000);

  return (
    <FormGroup className="mb-0">
      <InputGroup
        className={classnames("input-group-merge", "input-group-sm", {
          focused: isSearchFocused,
        })}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fas fa-search" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder={t("placeholders.search", "Search")}
          onFocus={() => {
            setSearchFocused(true);
          }}
          onBlur={() => {
            setSearchFocused(false);
          }}
          onChange={(e) => {
            searchDebounced(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              searchDebounced.flush();
            }
          }}
        />
      </InputGroup>
    </FormGroup>
  );
}
