import React, { useState } from "react";

// constants
import * as USER_TYPES from "../../../constants/deprecatedUserTypes";
import { LIST as SYSTEM_ROLE_LIST, ROLES } from "../../../constants/roles";
import {
  LOCAL_USER_MODAL,
  PROVIDER_USER_MODAL,
  CHANGE_PASSWORD_MODAL,
  USER_SITES_MODAL,
} from "../../../modals/types";

// utils
import { useTranslation } from "react-i18next";
import { formatDate, objectIdToTimestamp } from "../../../helpers/date";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../actions/modals";

import { UncontrolledTooltip } from "reactstrap";

import Microsoft from "../../../assets/img/icons/common/microsoft.svg";
import { useEffect } from "react";

export default function UserRow(props) {
  const { user } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.roles);

  const [systemRoles, setSystemRoles] = useState([]);

  useEffect(() => {
    if (user.roles) {
      user.roles.forEach((roleId) => {
        const role = roles.find((e) => e._id === roleId);
        if (role && role.system) {
          setSystemRoles((e) => [...e, role.name]);
        }
      });
    }
  }, [user.roles]);

  return (
    <>
      <td style={{ textAlign: "center" }}>
        {user.type === USER_TYPES.AZURE ? (
          <img src={Microsoft} alt="Microsoft logo" />
        ) : (
          <i className="fas fa-user"></i>
        )}
      </td>
      <td>
        <b>
          {systemRoles.includes(ROLES.ADMIN) ? (
            <>
              <i
                id={`role${user._id}`}
                className="fas fa-crown text-warning"
                style={{ marginRight: 10 }}
              />
              <UncontrolledTooltip delay={0} target={`role${user._id}`}>
                {t("tooltips.workspaceAdmin")}
              </UncontrolledTooltip>
            </>
          ) : null}
          {user.name ? user.name : `${user.firstName} ${user.lastName}`}
        </b>
      </td>
      <td>
        {user.roles.map((roleId, index) => {
          const role = roles.find((e) => e._id === roleId);
          if (role) {
            if (role.system) {
              const presetRole = SYSTEM_ROLE_LIST.find((e) => e.value === role.name);
              return `${index !== 0 ? "; " : ""}${t(presetRole.translationString)}`;
            } else {
              return `${index !== 0 ? "; " : ""}${role.name}`;
            }
          }
          return null;
        })}
      </td>
      <td>{formatDate(objectIdToTimestamp(user._id))}</td>

      <td className="table-action">
        {user.type === USER_TYPES.LOCAL ? (
          <>
            <button
              id={`userPasswordTooltip${user._id}`}
              type="button"
              className="btn btn-link table-action"
              onClick={() => {
                dispatch(
                  openModal(CHANGE_PASSWORD_MODAL, {
                    data: {
                      _id: user._id,
                    },
                  })
                );
              }}
            >
              <i className="fas fa-unlock-alt" />
            </button>
            <UncontrolledTooltip delay={0} target={`userPasswordTooltip${user._id}`}>
              {t("tooltips.changePassword")}
            </UncontrolledTooltip>
          </>
        ) : null}

        {systemRoles.includes(ROLES.USER) ? (
          <>
            <button
              id={`userWarehouseTooltip${user._id}`}
              type="button"
              className="btn btn-link table-action"
              onClick={() =>
                dispatch(
                  openModal(USER_SITES_MODAL, {
                    data: user,
                  })
                )
              }
            >
              <i className="fas fa-warehouse" />
            </button>
            <UncontrolledTooltip delay={0} target={`userWarehouseTooltip${user._id}`}>
              {t("tooltips.manageUserSites")}
            </UncontrolledTooltip>
          </>
        ) : null}

        <button
          id={`userTooltip${user._id}`}
          type="button"
          className="btn btn-link table-action"
          onClick={() =>
            dispatch(
              openModal(user.type === USER_TYPES.LOCAL ? LOCAL_USER_MODAL : PROVIDER_USER_MODAL, {
                data: user,
              })
            )
          }
        >
          <i className="fas fa-user-edit" />
        </button>
        <UncontrolledTooltip delay={0} target={`userTooltip${user._id}`}>
          {t("tooltips.editUser")}
        </UncontrolledTooltip>
      </td>
    </>
  );
}
