import React, { useState } from "react";

// constants & types
import { IVisitorModalProps } from "../types/Modal";
import { Visitor, VISITOR_STATUS } from "../types/Visitor";
import { MODULES } from "../constants/modules";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import visitorSchema from "../schemas/visitor";

// components
import { Button, Form, Modal } from "reactstrap";
import { Formik } from "formik";

import PermissionGuard from "../components/PermissionGuard";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LabelDatePickerFormGroup from "../components/Inputs/LabelDatePickerFormGroup";

const initialValues: Visitor = {
  status: VISITOR_STATUS.ARRIVED,
  firstName: "",
  lastName: "",
  arrived: moment().toDate(),
  notes: "",
};

export default function VisitorModal(props: IVisitorModalProps) {
  const { id, visitor, siteId, callback } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleteing] = useState(false);

  const onDelete = (id: string) => {
    setDeleteing(true);
    axios
      .delete(`/api/visitors/${id}`)
      .then((res) => {
        callback();
        close();
      })
      .catch((err) => {
        console.log("err:", err);
        setDeleteing(false);
      });
  };

  const onSubmit = (values: Visitor) => {
    setSubmitting(true);
    if (values._id) {
      axios
        .put(`/api/visitors/${values._id}`, values)
        .then(() => {
          callback();
          close();
        })
        .catch((err) => {
          setSubmitting(false);
        });
    } else {
      axios
        .post("/api/visitors", { siteId, ...values })
        .then(() => {
          callback();
          close();
        })
        .catch((err) => {
          setSubmitting(false);
        });
    }
  };

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{ ...initialValues, ...(visitor ? visitor : {}) }}
        validationSchema={visitorSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id ? t("modalTitles.editVisitor") : t("modalTitles.newVisitor")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.firstName")}
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                touched={touched.firstName}
                validationErrors={errors.firstName}
              />
              <LabelFormGroup
                label={t("fields.lastName")}
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                touched={touched.lastName}
                validationErrors={errors.lastName}
              />
              <LabelDatePickerFormGroup
                label={t("fields.arrived")}
                value={moment(values.arrived).local()}
                onChange={(e: Date) => setFieldValue("arrived", e)}
                touched={touched.arrived}
                validationErrors={errors.arrived}
              />
              {values._id ? (
                <LabelDatePickerFormGroup
                  label={t("fields.left")}
                  value={values.left ? moment(values.left).local() : null}
                  onChange={(e: Date) => setFieldValue("left", e)}
                  touched={touched.left}
                  validationErrors={errors.left}
                />
              ) : null}
              <LabelFormGroup
                label={t("fields.notes")}
                name="notes"
                value={values.notes}
                onChange={handleChange}
                touched={touched.notes}
                validationErrors={errors.notes}
              />
            </div>
            <div className="modal-footer">
              {values._id ? (
                <PermissionGuard
                  module={MODULES.visitorRegistry}
                  permission={ROLE_PERMISSIONS.DELETE}
                >
                  {({ hasAccess }) => (
                    <SpinnerButton
                      isLoading={deleting}
                      disabled={!hasAccess}
                      color="danger"
                      onClick={() => {
                        if (values._id) {
                          onDelete(values._id);
                        }
                      }}
                    >
                      {t("buttons.delete")}
                    </SpinnerButton>
                  )}
                </PermissionGuard>
              ) : null}

              <PermissionGuard
                module={MODULES.visitorRegistry}
                permission={values._id ? ROLE_PERMISSIONS.UPDATE : ROLE_PERMISSIONS.WRITE}
              >
                {({ hasAccess }) => (
                  <SpinnerButton
                    isLoading={submitting}
                    disabled={!hasAccess}
                    type="submit"
                    className="ml-auto"
                    color="primary"
                  >
                    {values._id ? t("buttons.save") : t("buttons.create")}
                  </SpinnerButton>
                )}
              </PermissionGuard>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
