import React, { useState, useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// utils
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import _ from "lodash";

// redux
import { useSelector } from "react-redux";

// reactstrap components
import { Collapse, Card, Row, Col, CardBody, Button } from "reactstrap";

// components
import LabelDatePickerFormGroup from "../../components/Inputs/LabelDatePickerFormGroup";
import LabelSelectFormGroup from "../../components/Inputs/LabelSelectFormGroup";
import LabelFormGroup from "../../components/Inputs/LabelFormGroup";

import { v4 as uuidv4 } from "uuid";

function EventsFilter(props) {
  const { onChange, isOpen } = props;

  const { t } = useTranslation();

  const eventTypes = useSelector((state) => state.eventTypes);

  const [data, setData] = useState({
    description: "",
    dateFrom: null,
    dateUntil: null,
    eventTypeId: "",
  });

  const debounced = useDebouncedCallback(() => {
    if (onChange) {
      onChange(_.omitBy(_.omitBy(data, _.isNil), _.isEmpty));
    }
  }, 1000);

  useEffect(() => {
    debounced();
  }, [data]);

  useEffect(() => {
    debounced.cancel();
  }, []);

  return (
    <Collapse isOpen={isOpen}>
      <Card>
        <CardBody>
          <Row>
            <Col xs="3" md="3">
              <LabelFormGroup
                label={t("fields.description")}
                value={data.description}
                onChange={(e) => {
                  setData((d) => ({ ...d, description: e.target.value }));
                }}
              />
            </Col>
            <Col xs="3" md="2">
              <LabelSelectFormGroup
                label={t("fields.type")}
                placeholder={t("placeholders.chose")}
                value={data.eventTypeId}
                onChange={(e) => setData((d) => ({ ...d, eventTypeId: e.target.value }))}
                options={eventTypes.map((eventType) => {
                  return {
                    value: eventType._id,
                    name: eventType.name,
                  };
                })}
              />
            </Col>
            <Col xs="3" md="2">
              <LabelDatePickerFormGroup
                label={t("fields.dateFrom")}
                //HOTFIX: using key prop to clear value, issue in underlying component [14.06.2021] https://github.com/arqex/react-datetime/issues/749
                key={data.dateFrom === null ? uuidv4() : null}
                value={moment(data.dateFrom)}
                onChange={(date) => setData((d) => ({ ...d, dateFrom: moment(date).format() }))}
              />
            </Col>
            <Col xs="3" md="2">
              <LabelDatePickerFormGroup
                label={t("fields.dateUntil")}
                key={data.dateUntil === null ? uuidv4() : null}
                value={moment(data.dateUntil)}
                onChange={(date) => setData((d) => ({ ...d, dateUntil: moment(date).format() }))}
              />
            </Col>
            <Button
              className="btn-sm align-self-end ml-auto mr-2"
              onClick={() => {
                setData(() => ({
                  description: "",
                  dateFrom: null,
                  dateUntil: null,
                  eventTypeId: "",
                }));
                debounced.flush();
              }}
            >
              {t("fields.clear")}
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
}

EventsFilter.propTypes = {
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EventsFilter;
