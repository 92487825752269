import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import * as msal from "@azure/msal-browser";
import axios from "axios";
import { useDispatch } from "react-redux";

import { setAuthenticated, setLogout } from "../actions/authentication";

import { config, loginRequest } from "../configs/msal";

import SpinnerButton from "./Buttons/SpinnerButton";

import Microsoft from "../assets/img/icons/common/microsoft.svg";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

export default function MicrosoftAuth(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [publicClient, setPublicClient] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const pc = new msal.PublicClientApplication(config);
    setPublicClient(pc);

    pc.handleRedirectPromise()
      .then((response) => {
        if (response) {
          setLoading(true);

          axios.interceptors.request.use(async function(config) {
            const accounts = pc.getAllAccounts();
            const res = await pc.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            });

            config.headers.Authorization = `Bearer ${res.idToken}`;

            return config;
          });

          dispatch(
            setLogout(() => {
              pc.logout();
            })
          );
          dispatch(setAuthenticated(true));
        } else {
          if (pc.getAllAccounts().length > 0) {
            setLoading(true);
            const accounts = pc.getAllAccounts();
            // console.log("accounts:", accounts);
            pc.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            })
              .then((res) => {
                axios.interceptors.request.use(async function(config) {
                  const accounts = pc.getAllAccounts();
                  const res = await pc.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                  });

                  config.headers.Authorization = `Bearer ${res.idToken}`;

                  return config;
                });

                dispatch(
                  setLogout(() => {
                    pc.logout();
                  })
                );
                dispatch(setAuthenticated(true));
              })
              .catch((err) => {
                if (err instanceof msal.InteractionRequiredAuthError) {
                  pc.loginRedirect(loginRequest);
                } else {
                  setLoading(false);
                  console.error(err);
                }
              });
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line
  }, []);

  const login = async (method) => {
    const signInType = isIE || isEdge ? "loginRedirect" : method;
    if (signInType === "loginPopup") {
      setPopupOpen(true);

      try {
        await publicClient.loginPopup(loginRequest);

        if (publicClient.getAllAccounts().length > 0) {
          setLoading(true);
          const accounts = publicClient.getAllAccounts();
          publicClient
            .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            })
            .then((res) => {
              axios.interceptors.request.use(async function(config) {
                const accounts = publicClient.getAllAccounts();
                const res = await publicClient.acquireTokenSilent({
                  ...loginRequest,
                  account: accounts[0],
                });

                config.headers.Authorization = `Bearer ${res.idToken}`;

                return config;
              });

              dispatch(
                setLogout(() => {
                  publicClient.logout();
                })
              );
              dispatch(setAuthenticated(true));
            })
            .catch((err) => {
              setLoading(false);
              console.error(err);
            });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPopupOpen(false);
      }
    } else if (signInType === "loginRedirect") {
      setLoading(true);

      publicClient.loginRedirect(loginRequest);
    }
  };

  return (
    <SpinnerButton
      isLoading={loading || popupOpen}
      className="btn-block btn-icon"
      color="microsoft-dark"
      onClick={() => {
        login("loginRedirect");
      }}
    >
      <span className="btn-inner--icon">
        <img src={Microsoft} alt="Microsoft logo" />
      </span>
      <span className="btn-inner--text">
        {t("buttons.microsoftAuth", "Sign in with Microsoft")}
      </span>
    </SpinnerButton>
  );
}
