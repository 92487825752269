import * as yup from "yup";
// import moment from "moment";

const scheduledVisitorSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(3)
    .required(),
  lastName: yup
    .string()
    .min(3)
    .required(),
  scheduledArrival: yup.date("validation.invalidDate").required("validation.dateRequired"),
  // .max(moment().add(1, "minute")),
  notes: yup.string().max(1000),
});

export default scheduledVisitorSchema;
