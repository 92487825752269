import React from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";

import enLocale from "@fullcalendar/core/locales/en-gb";
import lvLocale from "@fullcalendar/core/locales/lv";
import ruLocale from "@fullcalendar/core/locales/ru";
import deLocale from "@fullcalendar/core/locales/de";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import Header from "./Header";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { openModal } from "actions/modals";
// import { CALENDAR_DATE_MODAL, SCHEDULE_VISITOR_MODAL } from "modals/types";

import LoadingOverlay from "../../components/LoadingOverlay";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";

export default function Calendar() {
  const { siteId } = useParams<{ siteId: string }>();

  const { i18n } = useTranslation();
  const calendarRef = useRef();

  const dispatch = useAppDispatch();

  // TODO: fix common with useAppSelector
  const common = useSelector((state) => (state as any).common);

  const [isLoading, setLoading] = useState(true);
  const [currentTitle, setCurrentTitle] = useState(null);
  const [events, setEvents] = useState([]);

  const loadEvents = () => {
    axios
      .get(`/api/calendar/${siteId}`, {
        params: (calendarRef.current as any).getApi().currentDataManager.data.dateProfile
          .activeRange,
      })
      .then((res) => {
        setEvents(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setCurrentTitle((calendarRef.current as any).getApi().view.title);
  }, [i18n.language]);

  useEffect(() => {
    if (currentTitle) {
      loadEvents();
    }
  }, [currentTitle]);

  useEffect(() => {
    setTimeout(() => {
      (calendarRef.current as any).getApi().updateSize();
    }, 200);
  }, [common.sidebarOpen]);

  return (
    <>
      <Header
        onPrevious={() => {
          (calendarRef.current as any).getApi().prev();
          setCurrentTitle((calendarRef.current as any).getApi().view.title);
        }}
        onNext={() => {
          (calendarRef.current as any).getApi().next();
          setCurrentTitle((calendarRef.current as any).getApi().view.title);
        }}
        onView={(view) => {
          (calendarRef.current as any).getApi().changeView(view);
        }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card className="card-calendar">
              <CardHeader>
                <h5 className="h3 mb-0">{currentTitle}</h5>
              </CardHeader>
              <CardBody className="p-0">
                <LoadingOverlay isLoading={isLoading}>
                  <FullCalendar
                    ref={calendarRef as any}
                    locales={[enLocale, lvLocale, ruLocale, deLocale]}
                    locale={i18n.language}
                    plugins={[interaction, dayGridPlugin, timeGridPlugin]}
                    firstDay={1}
                    initialView="dayGridMonth"
                    windowResizeDelay={200}
                    contentHeight="auto"
                    events={events}
                    eventClick={(data) => {
                      const tmpVisitor = events.find((e) => (e as any)._id === data.event.id);

                      dispatch(
                        openModal({
                          siteId: siteId,
                          type: MODALS.ScheduleVisitor,
                          callback: loadEvents,
                          visitor: tmpVisitor,
                        })
                      );
                    }}
                    dateClick={(e) => {
                      dispatch(
                        openModal({
                          type: MODALS.CalendarDate,
                          siteId,
                          date: e.date,
                          callback: loadEvents,
                        })
                      );
                    }}
                    eventDataTransform={(e) => {
                      return {
                        id: e._id,
                        start: e.scheduledArrival,
                        className: "bg-green",
                        title: `${e.firstName} ${e.lastName}`,
                      };
                    }}
                    eventDisplay="block"
                    viewClassNames="calendar"
                    headerToolbar={false}
                    selectable
                    viewDidMount={(e) => {
                      setCurrentTitle(e.view.title as any);
                    }}
                    eventTimeFormat={{
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }}
                  />
                </LoadingOverlay>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
