import * as yup from "yup";
// import moment from "moment";

const transportSchema = yup.object().shape({
  transportTypeId: yup.string().required(),
  driverFirstName: yup
    .string()
    .required()
    .min(3),
  driverLastName: yup
    .string()
    .required()
    .min(3),
  carNumber: yup
    .string()
    .required()
    .min(2),
  arrived: yup.date("validation.invalidDate").required("validation.dateRequired"),
  left: yup.date("validation.invalidDate").nullable(),
  notes: yup.string().max(1000),
});

export default transportSchema;
