import * as yup from "yup";

const lockerSchema = yup.object().shape({
  name: yup.string().required(),
  pages: yup.number().required(),
  columns: yup.number().required(),
  rows: yup.number().required(),
});

export default lockerSchema;
