import { t } from "../helpers/common";
import { MODULES } from "./modules";
import { WORKFLOW_COUNT_MODAL } from "../modals/types";

export enum WidgetTypes {
  workflowCount = "workflow_count",
  visitorsToday = "visitors_today",
  visitorsWeek = "visitors_week",
  transportsToday = "transports_today",
  transportsWeek = "transports_week",
}

interface IWidget {
  value: WidgetTypes;
  translationString: string;
  modal?: typeof WORKFLOW_COUNT_MODAL;
  allowedModules: MODULES[];
}

export const VISITORS_TODAY: IWidget = {
  value: WidgetTypes.visitorsToday,
  translationString: t("constants.widgets.visitors_today", "Today's visitors"),
  allowedModules: [MODULES.visitorRegistry, MODULES.Calendar],
};

export const VISITORS_WEEK: IWidget = {
  value: WidgetTypes.visitorsWeek,
  translationString: t("constants.widgets.visitors_week", "Week visitors"),
  allowedModules: [MODULES.visitorRegistry, MODULES.Calendar],
};

export const TRANSPORTS_TODAY: IWidget = {
  value: WidgetTypes.transportsToday,
  translationString: t("constants.widgets.transports_today", "Today's transports"),
  allowedModules: [MODULES.transportRegistry],
};

export const TRANSPORTS_WEEK: IWidget = {
  value: WidgetTypes.transportsWeek,
  translationString: t("constants.widgets.transports_week", "Week transports"),
  allowedModules: [MODULES.visitorRegistry],
};

export const LIST: IWidget[] = [
  {
    value: WidgetTypes.workflowCount,
    translationString: t("constants.widgets.workflow_count", "Workflow count widget"),
    modal: WORKFLOW_COUNT_MODAL,
    allowedModules: [MODULES.Workflows],
  },
  VISITORS_TODAY,
  VISITORS_WEEK,
  TRANSPORTS_TODAY,
  TRANSPORTS_WEEK,
];
