import React from "react";
import PropTypes from "prop-types";

// utils
import { useTranslation } from "react-i18next";

// components
import { Button, Container, Row, Col } from "reactstrap";

function CalendarHeader(props) {
  const { onPrevious, onNext, onView } = props;

  const { t } = useTranslation();

  return (
    <div className="header header-dark bg-secondary pb-6 content__title content__title--calendar">
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col lg="6">
              <h6 className="fullcalendar-title h2 text-default d-inline-block mb-0 mr-1">
                {t("pages.calendar", "Calendar")}
              </h6>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6">
              <Button
                className="fullcalendar-btn-prev btn-neutral text-default"
                color="default"
                onClick={onPrevious}
                size="sm"
              >
                <i className="fas fa-angle-left" />
              </Button>
              <Button
                className="fullcalendar-btn-next btn-neutral text-default"
                color="default"
                onClick={onNext}
                size="sm"
              >
                <i className="fas fa-angle-right" />
              </Button>
              <Button
                className="btn-neutral text-default"
                color="default"
                data-calendar-view="month"
                onClick={() => {
                  onView("dayGridMonth");
                }}
                size="sm"
              >
                {t("buttons.month", "Month")}
              </Button>
              <Button
                className="btn-neutral text-default"
                color="default"
                data-calendar-view="basicWeek"
                onClick={() => {
                  onView("timeGridWeek");
                }}
                size="sm"
              >
                {t("buttons.week", "Week")}
              </Button>
              <Button
                className="btn-neutral text-default"
                color="default"
                data-calendar-view="basicDay"
                onClick={() => {
                  onView("timeGridDay");
                }}
                size="sm"
              >
                {t("buttons.day", "Day")}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

CalendarHeader.propTypes = {
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onView: PropTypes.func,
};

export default CalendarHeader;
