import { t } from "../helpers/common";
import { colors } from "./colors";

export enum severities {
  Minor = "minor",
  Moderate = "moderate",
  Dangerous = "dangerous",
  Critical = "critical",
}

interface ISeverity {
  value: severities;
  translationString: string;
  color: colors;
}

export const SEVERITIES: ISeverity[] = [
  {
    value: severities.Minor,
    translationString: t("constants.priorities.minor"),
    color: colors.Info,
  },
  {
    value: severities.Moderate,
    translationString: t("constants.priorities.moderate"),
    color: colors.Blue,
  },
  {
    value: severities.Dangerous,
    translationString: t("constants.priorities.dangerous"),
    color: colors.Yellow,
  },
  {
    value: severities.Critical,
    translationString: t("constants.priorities.critical"),
    color: colors.Red,
  },
];
