import React, { useState, useEffect } from "react";

// constants
import * as scopes from "../actions/scopes";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { Formik } from "formik";
import keySchema from "../schemas/key";

// components
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LabelDatePickerFormGroup from "../components/Inputs/LabelDatePickerFormGroup";

// Redux
import { useDispatch } from "react-redux";

// actions
import { closeModalById } from "../actions/modals";
import { setPaginationData } from "../actions/common";

export default function KeyModal(props) {
  const { modalId, siteId, lockerId, keyId, data, callback } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log("data:", data);
  }, [data]);

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          firstName: "",
          lastName: "",
          note: "",
          ...data,
          issued: data ? moment(data.issued) : null,
          returned: data ? moment(data.returned) : null,
        }}
        validationSchema={keySchema}
        onSubmit={(values) => {
          setIsSubmitting(true);
          if (values._id) {
            axios
              .post("/api/issuedKeys/update", { ...data, ...values })
              .then((res) => {
                callback(res.data);
                dispatch(
                  setPaginationData(scopes.KEYS, {
                    page: 1,
                    timestamp: moment().unix(),
                  })
                );
                close();
              })
              .catch((err) => {
                setIsSubmitting(false);
                console.log("error:", err);
              });
          } else {
            const { firstName, lastName, note } = values;
            console.log("submitting:", {
              siteId,
              lockerId,
              keyId,
              firstName,
              lastName,
              note,
            });

            axios
              .post("/api/issuedKeys/issue", {
                siteId,
                lockerId,
                keyId,
                firstName,
                lastName,
                note,
              })
              .then((res) => {
                callback(res.data);
                dispatch(
                  setPaginationData(scopes.KEYS, {
                    page: 1,
                    timestamp: moment().unix(),
                  })
                );
                close();
              })
              .catch((err) => {
                setIsSubmitting(false);
                console.log("error:", err);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id
                  ? t("modalTitles.editIssuedKey")
                  : t("modalTitles.issueKey")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.firstName")}
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                touched={touched.firstName}
                validationErrors={errors.firstName}
              />
              <LabelFormGroup
                label={t("fields.lastName")}
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                touched={touched.lastName}
                validationErrors={errors.lastName}
              />
              <LabelFormGroup
                label={t("fields.note")}
                name="note"
                type="textarea"
                value={values.note}
                onChange={handleChange}
                touched={touched.note}
                validationErrors={errors.note}
              />
              {values._id ? (
                <>
                  <LabelDatePickerFormGroup
                    label={t("fields.issued")}
                    value={values.issued}
                    onChange={(e) => setFieldValue("issued", e)}
                    touched={touched.issued}
                    validationErrors={errors.issued}
                  />{" "}
                  <LabelDatePickerFormGroup
                    label={t("fields.returned")}
                    value={values.returned}
                    onChange={(e) => setFieldValue("returned", e)}
                    touched={touched.returned}
                    validationErrors={errors.returned}
                  />
                </>
              ) : null}
            </div>
            <div className="modal-footer">
              {/* {values._id ? (
                <SpinnerButton
                  color="danger"
                  onClick={() => {
                    setIsDeleteing(true);
                    dispatch(deleteEventType(values._id));
                  }}
                  isLoading={isDeleting}
                  disabled={isDeleting || isSubmitting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null} */}
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={isSubmitting}
                // disabled={isDeleting || isSubmitting}
              >
                {t("buttons.save")}
              </SpinnerButton>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
