import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { LANGUAGES } from "../../constants.js";

// react library for routing
// import { NavLink as NavLinkRRD } from "react-router-dom";

// utils
import i18n from "../../i18n.js";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import moment from "moment";

// redux
import { useSelector } from "react-redux";

// components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // FormGroup,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Media,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  Container,
} from "reactstrap";

function NavigationBar(props) {
  const { theme, open, onToggle } = props;

  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("I18N_LANGUAGE") || "en"
  );

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    localStorage.setItem("I18N_LANGUAGE", currentLanguage);
    i18n.changeLanguage(currentLanguage);
    moment.locale(currentLanguage);
  }, [currentLanguage]);

  return (
    <Navbar
      className={classnames(
        "navbar-top navbar-expand border-bottom",
        { "navbar-dark bg-info": theme === "dark" },
        { "navbar-light bg-secondary": theme === "light" }
      )}
    >
      <Container fluid>
        <Collapse navbar isOpen={true}>
          {/* <Form
        className={classnames(
          "navbar-search form-inline mr-sm-3",
          { "navbar-search-light": this.props.theme === "dark" },
          { "navbar-search-dark": this.props.theme === "light" }
        )}
      >
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Search" type="text" />
          </InputGroup>
        </FormGroup>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={this.closeSearch}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </Form> */}

          <Nav className="align-items-center ml-md-auto" navbar>
            <NavItem className="d-xl-none">
              <div
                className={classnames(
                  "pr-3 sidenav-toggler",
                  { active: !open },
                  { "sidenav-toggler-dark": theme === "dark" }
                )}
                onClick={onToggle}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-default" />
                  <i className="sidenav-toggler-line bg-default" />
                  <i className="sidenav-toggler-line bg-default" />
                </div>
              </div>
            </NavItem>
            {/* <NavItem className="d-sm-none">
              <NavLink onClick={this.openSearch}>
                <i className="ni ni-zoom-split-in" />
              </NavLink>
            </NavItem> */}
            {/* {this.props.auth.user.sites &&
            this.props.auth.user.sites.length > 1 ? (
              <NavItem>
                <NavLink to="/" tag={NavLinkRRD}>
                  <i className="fas fa-warehouse" />
                </NavLink>
              </NavItem>
            ) : null} */}

            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link text-default" color="" tag="a" href="#">
                <i className="ni ni-world-2" />
              </DropdownToggle>
              <DropdownMenu right>
                {LANGUAGES.map((language) => (
                  <DropdownItem
                    key={language.languageCode}
                    onClick={() => setCurrentLanguage(language.languageCode)}
                    disabled={currentLanguage === language.languageCode}
                  >
                    <ReactCountryFlag code={language.countryCode} svg /> {language.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link pr-0" color="" tag="a" href="#">
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <i className="fas fa-user" />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">{auth.user.name}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{t("messages.welcome", "Welcome!")}</h6>
                </DropdownItem>
                <DropdownItem tag={Link} to="/profile">
                  <i className="ni ni-single-02" />
                  <span>{t("buttons.myProfile", "My profile")}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    auth.logout();
                    window.location.href = "/";
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>{t("buttons.logout", "Logout")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

NavigationBar.defaultProps = {
  onToggle: () => {},
  open: false,
  theme: "light",
};

NavigationBar.protoTypes = {
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default NavigationBar;
