import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { MODULE_LIST } from "../constants/modules";
import { LIST as PERMISSION_LIST } from "../constants/permissions";

import { useSelector } from "react-redux";
import { hasModuleAccess } from "../helpers/access";

function PermissionCheck(props) {
  const { module, level } = props;

  const user = useSelector((state) => state.auth.user);

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (hasModuleAccess(user.modules, module, level)) {
      setHasAccess(true);
    } else {
      setHasAccess(false);
    }
  }, [module, level, user]);

  return <>{hasAccess ? props.children : null}</>;
}

PermissionCheck.propTypes = {
  module: PropTypes.oneOf(MODULE_LIST.map((module) => module.value)).isRequired,
  level: PropTypes.oneOf(PERMISSION_LIST.map((permission) => permission.value)).isRequired,
};

export default PermissionCheck;
