import React from "react";

import { IEvent } from "../types/Event";
import { ISite } from "../types/Site";

import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatDatetime } from "../helpers/date";

import LARSLogo from "../assets/img/brand/logo_small_1.png";
import LARSIconBW from "../assets/img/brand/logo_bw.png";

import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";

// Create styles
const styles = StyleSheet.create({
  regularFont: {
    fontFamily: "Roboto",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  card: {
    borderBottom: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  cardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 30,
  },
  logo: {
    maxWidth: 100,
    maxHeight: 40,
    paddingRight: 20,
  },
  site: {
    paddingBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: 12,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  footerText: {
    fontSize: 8,
    color: "#D3D3D3",
  },
  smallLogo: {
    width: 20,
    paddingRight: 10,
  },
});

Font.register({ family: "Roboto", src: RobotoRegular });

const EventsPdf = (site: ISite, events: IEvent[]) => {
  return (
    <Document creator="LARS" producer="ZEVS" title={site.name}>
      <Page style={styles.body}>
        <View style={styles.footer} fixed>
          <Image style={styles.smallLogo} src={LARSIconBW} />
          <Text style={[styles.regularFont, styles.footerText]}>PDF generated with LARS</Text>
        </View>

        <View style={styles.site}>
          <View>
            <Text style={styles.regularFont}>{site.name}</Text>
            <Text style={[styles.regularFont, styles.subtitle]}>{site.address}</Text>
            <Text style={[styles.regularFont, styles.subtitle]}>
              {site.contactName} {site.contactNumber}
            </Text>
          </View>
          <View>
            <Image
              style={styles.logo}
              src={
                site.workspaceId.logo
                  ? `${process.env.REACT_APP_API_URL}uploads/${site.workspaceId.logo.key}`
                  : LARSLogo
              }
            />
          </View>
        </View>
        {events.map((event) => (
          <View key={event._id} style={styles.card}>
            <View style={styles.cardHeader}>
              <Text style={styles.regularFont}>
                {event.eventType ? event.eventType.name : null}
              </Text>
              <Text>{formatDatetime(event.occured)}</Text>
            </View>
            <Text style={[styles.regularFont, styles.subtitle]}>{event.description}</Text>
            {event.images.map((image) => (
              <Image
                key={image._id}
                style={styles.image}
                src={`${process.env.REACT_APP_API_URL}uploads/${image.original.key}`}
              />
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default EventsPdf;
