import React from "react";

import { PRIORITIES } from "../constants.js";

// i18n
import { useTranslation } from "react-i18next";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../actions/modals";
import { EVENT_TYPES_MODAL } from "../modals/types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";

// core components
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";

function EventTypes(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eventTypes = useSelector((state) => state.eventTypes);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-history mr-1" />
            {t("pages.eventTypes")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col className="text-right" xs="12">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      id="tooltip443412080"
                      onClick={() => {
                        dispatch(openModal(EVENT_TYPES_MODAL));
                      }}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">{t("buttons.create")}</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip443412080">
                      {t("tooltips.createEventType")}
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush table-hover" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: 1 }}>{t("fields.priority")}</th>
                    <th>{t("fields.name")}</th>
                    <th style={{ width: 1 }} />
                  </tr>
                </thead>
                <tbody>
                  {eventTypes.map((eventType, index) => (
                    <tr key={index}>
                      <td>
                        <Badge
                          key={index}
                          color={
                            PRIORITIES.find((priority) => priority.value === eventType.priority)
                              .color
                          }
                          pill
                        >
                          {t(
                            PRIORITIES.find((priority) => priority.value === eventType.priority)
                              .translationString
                          )}
                        </Badge>
                      </td>
                      <td>
                        <b>{eventType.name} </b>
                      </td>
                      <td className="table-action">
                        <button
                          id={`siteTooltip${index}`}
                          type="button"
                          className="btn btn-link table-action"
                          onClick={() => dispatch(openModal(EVENT_TYPES_MODAL, eventType))}
                        >
                          <i className="fas fa-edit" />
                        </button>
                        <UncontrolledTooltip delay={0} target={`siteTooltip${index}`}>
                          {t("tooltips.editEventType")}
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default EventTypes;
