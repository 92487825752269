import * as yup from "yup";

const geolocationSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(200),
  latitude: yup
    .number()
    .required()
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .required()
    .min(-180)
    .max(180),
  radius: yup
    .number()
    .required()
    .min(0)
    .max(1000),
});

export default geolocationSchema;
