import React from "react";

// constants & types
import MODALS from "../constants/modals";

// utils
import { useAppSelector } from "../hooks/redux";

// modals
import RoleModal from "../modals/Role";
import WorkflowSitesModal from "../modals/WorkflowSites";
import VisitorModal from "../modals/Visitor";
import ScheduleVisitorModal from "../modals/ScheduleVisitor";
import CalendarDateModal from "../modals/CalendarDate";
import TransportsModal from "../modals/Transport";
import QrModal from "../modals/Qr";
import GeolocationCollectionModal from "../modals/GeolocationCollection";
import MapModal from "../modals/Map";
import SiteAccessModal from "../modals/SiteAccess";

import WorkflowAddStepModal from "../modals/WorkflowAddStep";
import WorkflowDuplicateModal from "../modals/WorkflowDuplicate";

const ModalManager: React.FunctionComponent<{}> = () => {
  const modals = useAppSelector((state) => state.modals);

  return modals.length === 0 ? null : (
    <>
      {modals.map((modal, index) => {
        switch (modal.type) {
          case MODALS.Role:
            return <RoleModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.WorkflowSites:
            return <WorkflowSitesModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Visitor:
            return <VisitorModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.ScheduleVisitor:
            return <ScheduleVisitorModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.CalendarDate:
            return <CalendarDateModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Transport:
            return <TransportsModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Qr:
            return <QrModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Map:
            return <MapModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.SiteAccess:
            return <SiteAccessModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.WorkflowAddStep:
            return <WorkflowAddStepModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.GeolocationCollection:
            return <GeolocationCollectionModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.WorkflowDuplicate:
            return <WorkflowDuplicateModal key={`modal_${modal.id}`} {...modal} />;
          default:
            console.error("Undefined modal component");
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
