import { t } from "./helpers/common";

export const PRIORITIES = [
  {
    value: "minor",
    translationString: t("constants.priorities.minor"),
    color: "info",
  },
  {
    value: "moderate",
    translationString: t("constants.priorities.moderate"),
    color: "primary",
  },
  {
    value: "dangerous",
    translationString: t("constants.priorities.dangerous"),
    color: "warning",
  },
  {
    value: "critical",
    translationString: t("constants.priorities.critical"),
    color: "danger",
  },
];

export const USER_ROLES = [
  {
    value: "owner",
    translationString: t("constants.roles.owner", "Owner"),
    exclude: true,
  },
  {
    value: "admin",
    translationString: t("constants.roles.admin", "Administratior"),
    exclude: true,
  },
  {
    value: "moderator",
    translationString: t("constants.roles.moderator"),
  },
  {
    value: "control",
    translationString: t("constants.roles.control"),
  },
  {
    value: "user",
    translationString: t("constants.roles.user"),
  },
];

export const ROLE_ADMIN = {
  value: "admin",
  translationString: t("constants.roles.admin"),
};
export const ROLE_USER = {
  value: "user",
  translationString: t("constants.roles.user"),
};

export const LANGUAGES = [
  {
    label: "English",
    languageCode: "en",
    countryCode: "gb",
  },
  {
    label: "Latviešu",
    languageCode: "lv",
    countryCode: "lv",
  },
  {
    label: "Русский",
    languageCode: "ru",
    countryCode: "ru",
  },
  {
    label: "German",
    languageCode: "de",
    countryCode: "de",
  },
];

// Data loading states
export const DATA_LOADING = 1;
export const DATA_SUCCESSFUL = 2;
export const DATA_FAILED = 3;
