import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// utils
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

// components
import { FormGroup } from "reactstrap";
import ReactDatetime from "react-datetime";

import moment from "moment";
import "moment/locale/lv";
import "moment/locale/ru";

moment.updateLocale("en", {
  week: {
    dow: 1,
    doy: 4,
  },
});

function LabelDatePickerGroup(props) {
  const { label, placeholder, touched, validationErrors, required, ...rest } = props;

  const { i18n } = useTranslation();

  const labelId = uniqid();

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(touched && (validationErrors ? true : false));
  }, [touched, validationErrors]);

  return (
    <FormGroup style={{ width: "100%" }}>
      <label className="form-control-label" htmlFor={labelId}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <ReactDatetime
        className="is-invalid"
        id={labelId}
        inputProps={{
          placeholder: placeholder ? placeholder : label,
          className: invalid ? "form-control is-invalid" : "form-control",
        }}
        dateFormat="DD.MM.YYYY."
        timeFormat="HH:mm"
        locale={i18n.language}
        {...rest}
      />
      {invalid ? <div className="invalid-feedback">{validationErrors}</div> : null}
    </FormGroup>
  );
}

LabelDatePickerGroup.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  ...ReactDatetime.propTypes,
};

export default LabelDatePickerGroup;
