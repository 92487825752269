import React from "react";

// // utils
import { useTranslation } from "react-i18next";

// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import MicrosoftAuth from "../components/MicrosoftAuth";
import LocalAuth from "../components/LocalAuth";

// core components
import AuthHeader from "../components/Headers/AuthHeader";
import Copyright from "../components/Copyright";
import backgroundImage from "../assets/img/brand/auth_background.svg";

function Login(props) {
  const { t } = useTranslation();
  return (
    <div
      className="main-content"
      style={{
        background: "#f6f6f6",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
      }}
    >
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="pb-4">
                  <Col xs={12}>
                    <MicrosoftAuth />
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col xs={12} className="text-center">
                    {t("texts.or", "Or")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <LocalAuth />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <footer className="py-3" id="footer-main">
        <Container className="text-center">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}

export default Login;
