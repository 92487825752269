import * as yup from "yup";

import { PRIORITIES } from "../constants.js";

const eventTypeSchema = yup.object().shape({
  name: yup.string().required(),
  priority: yup
    .string()
    .oneOf(PRIORITIES.map(priority => priority.value))
    .required()
});

export default eventTypeSchema;
