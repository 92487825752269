import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import ColorHash from "color-hash";

// components
import Skeleton from "react-loading-skeleton";
import { transportRoutes } from "../../helpers/apiRoutes";
import WidgetContainer from "../WidgetContainer";

import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ITransportCount {
  _id: {
    date: string;
    transportType: { _id: string; name: string; workspaceId: string };
  };
  count: number;
}

interface IProps {
  widgetId: string;
  siteId: string;
  onDelete: (id: string) => any;
}

let weekDates: Date[] = [];
for (let day = 6; day >= 0; day--) {
  weekDates.push(
    moment()
      .subtract(day, "days")
      .toDate()
  );
}

export default function TransportsWeek({ widgetId, siteId, onDelete }: IProps) {
  const colorHash = new ColorHash();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITransportCount[]>([]);

  const { t } = useTranslation();

  const onRemove = () => {
    onDelete(widgetId);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get<ITransportCount[]>(transportRoutes.get.transportsWeek(siteId))
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [widgetId]);

  return (
    <WidgetContainer title={t("widgets.transportsWeek")} onRemove={onRemove}>
      {loading ? (
        <Skeleton height={35} />
      ) : (
        <Bar
          options={{
            plugins: {
              datalabels: {
                color: "#fff",
                display: function(context) {
                  return context.dataset.data[context.dataIndex] !== 0;
                },
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                ticks: {
                  stepSize: 1,
                },
              },
            },
          }}
          data={{
            labels: weekDates.map((date) => moment(date).format("DD.MM.")),
            datasets: _.map(
              _.groupBy(data, (item) => item._id.transportType.name),
              (group, groupKey) => {
                let tmp: number[] = [];
                weekDates.forEach((date) => {
                  let transportDate = group.find((d) =>
                    moment(d._id.date, "DD.MM.YYYY.").isSame(date, "day")
                  );

                  if (transportDate) {
                    tmp.push(transportDate.count);
                  } else {
                    tmp.push(0);
                  }
                });

                return {
                  label: groupKey,
                  data: tmp,
                  backgroundColor: colorHash.hex(groupKey),
                };
              }
            ),
          }}
        />
      )}
    </WidgetContainer>
  );
}
