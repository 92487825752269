import i18n from "../i18n";

export enum workflowChecklistTypes {
  Single = "single",
  All = "all",
  AtLeaseOne = "at_least_one",
  Any = "any",
}

interface IWorkflowChecklistType {
  label: string;
}

type WorkflowChecklistTypes = {
  [key in workflowChecklistTypes]: IWorkflowChecklistType;
};

export const CHECKLIST_TYPES: WorkflowChecklistTypes = {
  [workflowChecklistTypes.Single]: {
    label: i18n.t("constants.workflowChecklistTypes.single", "Single"),
  },
  [workflowChecklistTypes.All]: {
    label: i18n.t("constants.workflowChecklistTypes.all", "All"),
  },
  [workflowChecklistTypes.AtLeaseOne]: {
    label: i18n.t("constants.workflowChecklistTypes.atLeastOne", "At least one"),
  },
  [workflowChecklistTypes.Any]: {
    label: i18n.t("constants.workflowChecklistTypes.any", "Any"),
  },
};
