import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import App from "./App";


// import i18n (needs to be bundled ;))
import "./i18n";

// Redux and redux store
import { Provider } from "react-redux";
import store from "./store";

// custom providers
import ErrorProvider from "./providers/ApiError";

// moment locales
import "moment/locale/lv";
import "moment/locale/ru";
import "moment/locale/de";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import "react-image-lightbox/style.css";

// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// core styles
import "./assets/css/argon-dashboard-pro-react.min.css";

import "leaflet/dist/leaflet.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <ErrorProvider>
        <App />
      </ErrorProvider>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
