import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";

// components
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { workflowRoutes } from "../../helpers/apiRoutes";
import WidgetContainer from "../WidgetContainer";

interface IExecutionCount {
  _id: string;
  name: string;
  executions: { today?: number; yesterday?: number; before?: number };
}
interface IProps {
  widgetId: string;
  workflowId: string;
  siteId: string;
  onDelete: (id: string) => any;
}

export default function WorkflowExecutionCount({ widgetId, workflowId, siteId, onDelete }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [executionCount, setExecutionCount] = useState<Partial<IExecutionCount>>({});

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    axios
      .get(workflowRoutes.get.executionCount(siteId, workflowId))
      .then((res) => {
        setExecutionCount(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [widgetId]);

  const onRemove = () => {
    onDelete(widgetId);
  };

  return (
    <WidgetContainer title={executionCount.name ? executionCount.name : ""} onRemove={onRemove}>
      <Row className="pt-3">
        <Col>
          <h5 className="card-title text-uppercase text-muted text-center mb-0">
            {loading ? <Skeleton /> : t("fields.yesterday")}
          </h5>
          <h1 className="font-weight-bold text-center mb-0">
            {loading ? (
              <Skeleton circle={true} height={35} width={35} />
            ) : executionCount.executions && executionCount.executions.yesterday ? (
              executionCount.executions.yesterday
            ) : (
              0
            )}
          </h1>
        </Col>
        <Col>
          <h5 className="card-title text-uppercase text-muted text-center mb-0">
            {loading ? <Skeleton /> : t("fields.today")}
          </h5>
          <h1 className="font-weight-bold text-center mb-0">
            {loading ? (
              <Skeleton circle={true} height={35} width={35} />
            ) : executionCount.executions && executionCount.executions.today ? (
              executionCount.executions.today
            ) : (
              0
            )}
          </h1>
        </Col>
        <Col>
          <h5 className="card-title text-uppercase text-muted text-center mb-0">
            {loading ? <Skeleton /> : t("fields.before")}
          </h5>
          <h1 className="font-weight-bold text-center mb-0">
            {loading ? (
              <Skeleton circle={true} height={35} width={35} />
            ) : executionCount.executions && executionCount.executions.before ? (
              executionCount.executions.before
            ) : (
              0
            )}
          </h1>
        </Col>
      </Row>
    </WidgetContainer>
  );

  // return (
  //   <WidgetContainer
  //     id={widgetId}
  //     widgetElements={
  //     }
  //     onRemove={onDelete}
  //   />
  // );
}
