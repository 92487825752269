import React from "react";

// Redux
import { connect } from "react-redux";

import {
  APPROVE_DIALOG_MODAL,
  ADD_WIDGET_DASHBOARD_MODAL,
  ADD_WIDGET_MODAL,
  EVENT_TYPES_MODAL,
  TRANSPORT_TYPES_MODAL,
  EVENT_MODAL,
  LOCAL_USER_MODAL,
  PROVIDER_USER_MODAL,
  PROFILE_PASSWORD_CHANGE_MODAL,
  CHANGE_PASSWORD_MODAL,
  USER_SITES_MODAL,
  SITE_MODAL,
  SITE_WORKFLOWS_MODAL,
  MANAGE_KEY_MODAL,
  KEY_MODAL,
  LOCKER_MODAL,
  WORKSPACE_SETTINGS_MODAL,
  WORKFLOW_MODAL,
  WORKFLOW_ADD_FORM_FIELD,
  WORKFLOW_COUNT_MODAL,
  TASK_MODAL,
} from "../modals/types";

// classifiers
import EventTypesModal from "../modals/EventTypes";
import TransportTypesModal from "../modals/TransportTypes";

import WorkspaceSettingsModal from "../modals/WorkspaceSettings";

import TaskModal from "../modals/Task";

import WorkflowModal from "../modals/Workflow";
// import WorkflowAddStepModal from "modals/WorkflowAddStep";
import WorkflowAddFormFieldModal from "../modals/WorkflowAddFormField";

import ApproveDialogPrompt from "../modals/ApproveDialogPrompt";
import AddWidgetDashboardModal from "../modals/AddWidgetDashboard";
import AddWidgetModal from "../modals/AddWidget";
import WorkflowCountModal from "../modals/WorkflowCount";

//
import EventModal from "../modals/Event";

import UserModal from "../modals/User";
import ProviderUserModal from "../modals/ProviderUser";
import ProfilePasswordChangeModal from "../modals/ProfilePasswordChange";
import ChangePasswordModal from "../modals/ChangePassword";
import UserSitesModal from "../modals/UserSites";
import SiteModal from "../modals/Site";
import SiteWorkflowsModal from "../modals/SiteWorkflows";
import ManageKeyModal from "../modals/ManageKey";
import KeyModal from "../modals/Key";
import LockerModal from "../modals/Locker";

const MODAL_COMPONENTS = {
  // owner modals
  [WORKSPACE_SETTINGS_MODAL]: WorkspaceSettingsModal,
  // Admin modals
  [EVENT_TYPES_MODAL]: EventTypesModal,
  [TRANSPORT_TYPES_MODAL]: TransportTypesModal,
  //... modals
  [APPROVE_DIALOG_MODAL]: ApproveDialogPrompt,
  [ADD_WIDGET_DASHBOARD_MODAL]: AddWidgetDashboardModal,
  [ADD_WIDGET_MODAL]: AddWidgetModal,
  [WORKFLOW_COUNT_MODAL]: WorkflowCountModal,
  [EVENT_MODAL]: EventModal,
  [LOCAL_USER_MODAL]: UserModal,
  [PROVIDER_USER_MODAL]: ProviderUserModal,
  [PROFILE_PASSWORD_CHANGE_MODAL]: ProfilePasswordChangeModal,
  [CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
  [USER_SITES_MODAL]: UserSitesModal,
  [SITE_MODAL]: SiteModal,
  [SITE_WORKFLOWS_MODAL]: SiteWorkflowsModal,
  [MANAGE_KEY_MODAL]: ManageKeyModal,
  [KEY_MODAL]: KeyModal,
  [LOCKER_MODAL]: LockerModal,

  // Workflows
  [WORKFLOW_MODAL]: WorkflowModal,
  // [WORKFLOW_ADD_STEP]: WorkflowAddStepModal,
  [WORKFLOW_ADD_FORM_FIELD]: WorkflowAddFormFieldModal,

  // Tasks
  [TASK_MODAL]: TaskModal,
};

const ModalManager = ({ modalId, modalName, modalProps }) => {
  if (!modalName) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalName];
  return <SpecificModal modalId={modalId} {...modalProps} />;
};

export default connect((state) => state.modal)(ModalManager);
