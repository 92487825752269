import React from "react";

// constants
// import * as scopes from "actions/scopes";
import { WORKFLOW_STEPS } from "../constants/workflowSteps";

// utils
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// Redux
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

// components
import { Button, Col, Container, ListGroup, ListGroupItem, Modal, Row } from "reactstrap";
import { IWorkflowAddStepModalProps } from "../types/Modal";
import _ from "lodash";

export default function WorkflowStepsModal({ id, workflowId }: IWorkflowAddStepModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="lg" toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workflowSteps")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <ListGroup>
          {_.map(WORKFLOW_STEPS, (step, index) => (
            <ListGroupItem
              key={index}
              action
              onClick={() => {
                // if (siteId) {
                //   history.push(`/site/${siteId}/tasks/${workflowId}/${step.value}/new`);
                // } else {
                history.push(`/workflows/${workflowId}/${index}/new`);
                // }
                close();
              }}
            >
              <Container fluid>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <div
                      className={`icon icon-shape text-white rounded-circle shadow`}
                      style={{ backgroundColor: step.color }}
                    >
                      <i className={`fas ${step.icon}`} />
                    </div>
                  </Col>
                  <Col>{step.label}</Col>
                </Row>
              </Container>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
