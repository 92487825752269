import React from "react";

import { LANGUAGES } from "../../constants.js";

// nodejs library to set properties for components
import PropTypes from "prop-types";

//i18n
import i18n from "../../i18n.js";
import ReactCountryFlag from "react-country-flag";

// reactstrap components
import {
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import logo from "../../assets/img/brand/logo_small_1024.png";

// TODO: this file to .tsx
function AuthHeader(props) {
  const setLanguage = (lng) => {
    if (localStorage.getItem("I18N_LANGUAGE") !== lng) {
      localStorage.setItem("I18N_LANGUAGE", lng);
      i18n.changeLanguage(lng);
    }
  };

  return (
    <>
      <div className="navbar-light">
        <UncontrolledDropdown
          className="navbar-nav"
          style={{ position: "absolute", right: 10, top: 10, zIndex: 9 }}
        >
          <DropdownToggle className="nav-link" color="" tag="a" href="#" style={{ padding: 8 }}>
            <i className="ni ni-world-2" />
          </DropdownToggle>
          <DropdownMenu right>
            {LANGUAGES.map((language) => (
              <DropdownItem
                key={language.languageCode}
                onClick={() => setLanguage(language.languageCode)}
              >
                <ReactCountryFlag code={language.countryCode} svg /> {language.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="header py-6">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                <img src={logo} alt="LARS logo" width={200} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
