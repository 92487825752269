import React, { useState } from "react";

// utils
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import transportTypeSchema from "../schemas/transportType";

// redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";
import {
  createTransportType,
  deleteTransportType,
  updateTransportType,
} from "../actions/classifiers/transportTypes";

// components
import { Modal, Form, Button } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

export default function TransportTypesModal(props) {
  const { modalId, ...rest } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleteing] = useState(false);

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          name: "",
          ...rest,
        }}
        validationSchema={transportTypeSchema}
        onSubmit={(values) => {
          setIsSubmitting(true);
          if (values._id) {
            dispatch(updateTransportType(values));
          } else {
            dispatch(createTransportType(values));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id
                  ? t("modalTitles.editTransportType")
                  : t("modalTitles.createTransportType")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
            </div>
            <div className="modal-footer">
              {values._id ? (
                <SpinnerButton
                  color="danger"
                  onClick={() => {
                    setIsDeleteing(true);
                    dispatch(deleteTransportType(values._id));
                  }}
                  isLoading={isDeleting}
                  disabled={isSubmitting || isDeleting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null}
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || isDeleting || !dirty}
              >
                {values._id ? t("buttons.save") : t("buttons.create")}
              </SpinnerButton>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
