import React from "react";
import PropTypes from "prop-types";

// components
import { Button, ButtonProps } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

interface SpinnerButtonProps extends ButtonProps {
  isLoading: boolean;
  disabled?: boolean;
}

function SpinnerButton(props: SpinnerButtonProps) {
  const { isLoading, disabled, children, ...rest } = props;
  return (
    <Button disabled={isLoading || disabled} {...rest}>
      {isLoading ? (
        <ClipLoader
          color={"#red"}
          size={32}
          css="position: absolute; top: calc(50% - 16px); left: calc(50% - 16px)"
        />
      ) : null}
      {children}
    </Button>
  );
}

SpinnerButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default SpinnerButton;
