import React, { useEffect, useState } from "react";

// constants & types
import { IRole, IRoleUser } from "../types/Role";

import { LIST as MODULE_LIST } from "../constants/modules";
import * as rolePermissions from "../constants/rolePermissions";

// utils
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import axios from "axios";
import { FieldArray, Formik } from "formik";
import roleSchema from "../schemas/role";
import { closeModalById } from "../features/modals";

import { appendRole, replaceRole, removeRole } from "../actions/classifiers/roles";

// components
import { Modal, Form, Button, Table } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

import { IRoleModalProps } from "../types/Modal";
import { IWorkspace } from "../types/Workspace";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";
import { IModule } from "../types/Module";

const RoleModal: React.FunctionComponent<IRoleModalProps> = ({ id, role, onSuccess, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const workspace = useAppSelector((state) => state.auth.user.workspace as IWorkspace);

  const [initialValues, setInitialValues] = useState<IRoleUser>({
    name: "",
    system: false,
    modules: [],
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleteing] = useState(false);

  const handleDelete = () => {
    setDeleteing(true);
    axios
      .delete(`/api/classifiers/roles/${initialValues._id}`)
      .then(() => {
        if (typeof onDelete === "function") {
          onDelete();
        }
        close();
      })
      .catch(() => {
        setDeleteing(false);
      });
  };

  useEffect(() => {
    if (role) {
      setInitialValues(role);
    }
  }, [role]);

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered modal-lg" isOpen={true} toggle={close}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={roleSchema}
        onSubmit={(values) => {
          console.log("values:", values);
          setSubmitting(true);
          if (values._id) {
            axios
              .put<IRoleUser>("/api/classifiers/roles", values)
              .then((response) => {
                setSubmitting(false);
                onSuccess();
                close();
              })
              .catch((err) => {
                setSubmitting(false);
                console.log("error:", err);
              });
          } else {
            axios
              .post("/api/classifiers/roles", values)
              .then((response) => {
                onSuccess();
                close();
              })
              .catch((err) => {
                setSubmitting(false);
                console.log("error:", err);
              });
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, dirty }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id ? t("modalTitles.editRole") : t("modalTitles.createRole")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
              <FieldArray
                name="modules"
                render={(arrayHelpers) => (
                  <Table>
                    <thead className="thead-light">
                      <tr>
                        <th>{t("fields.permission")}</th>
                        {rolePermissions.LIST.map((permission) => (
                          <th key={permission.value}>{t(permission.translationString)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {workspace.modules.map((moduleValue, index) => {
                        const module = MODULE_LIST.find((m) => m.value === moduleValue);
                        const modulePermission = values.modules.find(
                          (m) => m.value === moduleValue
                        );

                        if (module) {
                          return (
                            <tr key={index}>
                              <td>
                                <b>{t(module.translationString)}</b>
                              </td>
                              {rolePermissions.LIST.map((permission, index) => (
                                <td key={`${index}_${permission}`}>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      className="custom-control-input"
                                      id={`${module.value}_${permission}_${index}`}
                                      type="checkbox"
                                      disabled={
                                        (module.maxPermission | permission.value) !==
                                        module.maxPermission
                                      }
                                      checked={
                                        modulePermission &&
                                        (modulePermission.permission & permission.value) ===
                                          permission.value
                                      }
                                      onChange={(e) => {
                                        if (modulePermission) {
                                          const valueIndex = values.modules.findIndex(
                                            (m) => m.value === moduleValue
                                          );

                                          const updatedModule: IModule = {
                                            value: modulePermission.value,
                                            permission: e.target.checked
                                              ? modulePermission.permission | permission.value
                                              : modulePermission.permission ^ permission.value,
                                          };
                                          arrayHelpers.replace(valueIndex, updatedModule);
                                        } else {
                                          const newModule: IModule = {
                                            value: module.value,
                                            permission: permission.value,
                                          };
                                          arrayHelpers.push(newModule);
                                        }
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`${module.value}_${permission}_${index}`}
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          );
                        }

                        return (
                          <tr key={index}>
                            <td>Undefined module</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              />
            </div>
            <div className="modal-footer">
              {/* {values._id ? (
                <SpinnerButton
                  color="danger"
                  onClick={onDelete}
                  isLoading={isDeleting}
                  disabled={isDeleting || isSubmitting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null} */}
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={isSubmitting}
                disabled={isDeleting || isSubmitting || !dirty}
              >
                {values._id ? t("buttons.save") : t("buttons.create")}
              </SpinnerButton>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RoleModal;
