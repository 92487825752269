import React from "react";

import * as MODULES from "../constants/modules";
import { ROLES } from "../constants/roles";

import { t } from "../helpers/common";

// views
import Dashboard from "../views/admin/Dashboard";
import EventTypes from "../views/EventTypes";
import TransportTypes from "../views/TransportTypes";

import WorkspaceSettings from "../views/Workspace/Settings";
import WorkspaceUsers from "../views/Workspace/Users";
import WorkspaceRoles from "../views/Workspace/Roles";
import WorkspaceAccessLog from "../views/Workspace/AccessLog";

import Workflows from "../views/Workflows";

import WorkflowEdit from "../views/Workflows/Edit";
import WorkflowLicensePlateStep from "../views/Workflows/Edit/Steps/LicensePlate";
import WorkflowCameraStep from "../views/Workflows/Edit/Steps/Camera";
import WorkflowFormStep from "../views/Workflows/Edit/Steps/Form";
import WorkflowSignatureStep from "../views/Workflows/Edit/Steps/Signature";
import WorkflowQrStep from "../views/Workflows/Edit/Steps/QrCode";
import WorkflowChecklist from "../views/Workflows/Edit/Steps/Checklist";
import GeolocationStep from "../views/Workflows/Edit/Steps/Geolocation";

import CentralOffice from "../views/CentralOffice";

import Sites from "../views/Sites";
import Profile from "../views/Profile";

import SiteDashboard from "../views/SiteDashboard";

import QrCollections from "../views/Workspace/Qr";
import EditQrCollection from "../views/Workspace/Qr/Edit";

import Geolocation from "../views/Workspace/Geolocations";
import EditGeolocation from "../views/Workspace/Geolocations/Edit";

import Events from "../views/Events/Events";

import Visitors from "../views/Visitors/Visitors";
import Transports from "../views/Transports/Transports";
import Calendar from "../views/Calendar";

import SiteWorkflowExecutions from "../views/executions/workflows";
import { workflowStepTypes } from "../constants/workflowSteps";

// import Tasks from "views/Tasks";

export default [
  {
    path: "/",
    component: Dashboard,
    translationString: t("pages.dashboard", "Dashboard"),
    icon: <i className="fas fa-columns text-default" />,
  },
  {
    path: "/workspace/",
    translationString: t("pages.worksapce", "Workspace"),
    icon: <i className="fas fa-object-group text-default" />,
    allowedRoles: [ROLES.ADMIN],
    routes: [
      {
        path: "/workspace/settings",
        component: WorkspaceSettings,
        translationString: t("pages.settings", "Settings"),
        icon: <i className="fas fa-cogs" />,
      },
      {
        path: "/workspace/users",
        component: WorkspaceUsers,
        translationString: t("pages.users", "Users"),
        icon: <i className="fas fa-users" />,
      },
      {
        path: "/workspace/roles",
        component: WorkspaceRoles,
        translationString: t("pages.roles", "Roles"),
        icon: <i className="fas fa-user-tag" />,
      },
      {
        path: "/workspace/access-log",
        component: WorkspaceAccessLog,
        translationString: t("pages.accessLog", "Access log"),
        icon: <i className="fas fa-address-book" />,
      },
    ],
  },
  {
    path: "/centralOffice",
    component: CentralOffice,
    translationString: t("pages.centralOffice", "Central office"),
    icon: <i className="fas fa-map-marked-alt text-default" />,
    // allowedModules: [MODULES.EVENT_REGISTRY],
    // allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: "/eventTypes",
    component: EventTypes,
    translationString: t("pages.eventTypes", "Event types"),
    icon: <i className="fas fa-history text-default" />,
    allowedModules: [MODULES.EVENT_REGISTRY],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: "/transportTypes",
    component: TransportTypes,
    translationString: t("pages.transportTypes", "Transport types"),
    icon: <i className="fas fa-truck text-default" />,
    allowedModules: [MODULES.TRANSPORT_REGISTRY],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: "/qr",
    component: QrCollections,
    translationString: t("pages.qrCodes", "QR codes"),
    icon: <i className="fas fa-qrcode" />,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: ["/qr/:qrCollectionId"],
    component: EditQrCollection,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: "/geolocation",
    component: Geolocation,
    translationString: t("pages.geolocation", "Geolocations"),
    icon: <i className="fas fa-map-marker-alt" />,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: ["/geolocation/:geolocationCollectionId"],
    component: EditGeolocation,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: "/workflows",
    component: Workflows,
    translationString: t("pages.workflows", "Workflows"),
    icon: <i className="fas fa-random text-default" />,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },
  {
    path: "/workflows/:workflowId",
    component: WorkflowEdit,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/geolocation/new",
      "/workflows/:workflowId/geolocation/:stepId",
      "/site/:siteId/tasks/:workflowId/geolocation/new",
      "/site/:siteId/tasks/:workflowId/geolocation/:stepId",
    ],
    component: GeolocationStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/qr/new",
      "/workflows/:workflowId/qr/:stepId",
      "/site/:siteId/tasks/:workflowId/qr/new",
      "/site/:siteId/tasks/:workflowId/qr/:stepId",
    ],
    component: WorkflowQrStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/signature/new",
      "/workflows/:workflowId/signature/:stepId",
      "/site/:siteId/tasks/:workflowId/signature/new",
      "/site/:siteId/tasks/:workflowId/signature/:stepId",
    ],
    component: WorkflowSignatureStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/license-plate/new",
      "/workflows/:workflowId/license-plate/:stepId",
      "/site/:siteId/tasks/:workflowId/license-plate/new",
      "/site/:siteId/tasks/:workflowId/license-plate/:stepId",
    ],
    component: WorkflowLicensePlateStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/form/new",
      "/workflows/:workflowId/form/:stepId",
      "/site/:siteId/tasks/:workflowId/form/new",
      "/site/:siteId/tasks/:workflowId/form/:stepId",
    ],
    component: WorkflowFormStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      "/workflows/:workflowId/camera/new",
      "/workflows/:workflowId/camera/:stepId",
      "/site/:siteId/tasks/:workflowId/camera/new",
      "/site/:siteId/tasks/:workflowId/camera/:stepId",
    ],
    component: WorkflowCameraStep,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: [
      `/workflows/:workflowId/${workflowStepTypes.Checklist}/new`,
      `/workflows/:workflowId/${workflowStepTypes.Checklist}/:stepId`,
      `/site/:siteId/tasks/:workflowId/${workflowStepTypes.Checklist}/new`,
      `/site/:siteId/tasks/:workflowId/${workflowStepTypes.Checklist}/:stepId`,
    ],
    component: WorkflowChecklist,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
    exclude: true,
  },
  {
    path: "/sites",
    component: Sites,
    translationString: t("pages.sites", "Sites"),
    icon: <i className="fas fa-warehouse text-default" />,
    allowedModules: [
      MODULES.EVENT_REGISTRY,
      MODULES.VISITOR_REGISTRY,
      MODULES.CALENDAR,
      MODULES.TRANSPORT_REGISTRY,
      MODULES.WORKFLOWS,
    ],
  },
  // site subroutes
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/dashboard",
    component: SiteDashboard,
    translationString: t("pages.dashboard", "Dashboard"),
    icon: <i className="fas fa-columns text-default" />,
    allowedModules: [
      MODULES.EVENT_REGISTRY,
      MODULES.VISITOR_REGISTRY,
      MODULES.CALENDAR,
      MODULES.TRANSPORT_REGISTRY,
      MODULES.WORKFLOWS,
    ],
  },
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/events",
    component: Events,
    translationString: t("pages.events", "Events"),
    icon: <i className="fas fa-history text-default" />,
    allowedModules: [MODULES.EVENT_REGISTRY],
  },
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/visitors",
    component: Visitors,
    translationString: t("pages.visitors", "Visitors"),
    icon: <i className="fas fa-users text-default" />,
    allowedModules: [MODULES.VISITOR_REGISTRY],
  },
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/transport",
    component: Transports,
    translationString: t("pages.transports", "Transport"),
    icon: <i className="fas fa-truck text-default" />,
    allowedModules: [MODULES.TRANSPORT_REGISTRY],
  },
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/calendar",
    component: Calendar,
    translationString: t("pages.calendar", "Calendar"),
    icon: <i className="fas fa-calendar-alt text-default"></i>,
    allowedModules: [MODULES.CALENDAR],
  },
  // {
  //   subRoute: true,
  //   match: /^\/site\//,
  //   path: "/site/:siteId/workflows",
  //   component: SiteWorkflowData,
  //   translationString: t("pages.workflows", "Workflows"),
  //   icon: <i className="fas fa-random text-default" />,
  //   allowedModules: [MODULES.WORKFLOWS],
  //   allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  //   depricated: true,
  // },
  {
    subRoute: true,
    match: /^\/site\//,
    path: "/site/:siteId/executions/workflows",
    component: SiteWorkflowExecutions,
    translationString: t("pages.workflowExecutions", "Workflow executions"),
    icon: <i className="fas fa-random text-default" />,
    allowedModules: [MODULES.WORKFLOWS],
    allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  },

  // {
  //   subRoute: true,
  //   match: /^\/site\//,
  //   path: "/site/:siteId/tasks",
  //   component: Tasks,
  //   translationString: t("pages.tasks", "Tasks"),
  //   icon: <i className="fas fa-tasks text-default" />,
  //   allowedModules: [MODULES.WORKFLOWS],
  // },
  // {
  //   path: "/site/:siteId/tasks/:workflowId",
  //   component: WorkflowEdit,
  //   allowedModules: [MODULES.WORKFLOWS],
  //   exclude: true,
  // },
  // {
  //   path: "/site/:siteId/workflows/:workflowId",
  //   component: SiteWorkflowData,
  //   allowedModules: [MODULES.WORKFLOWS],
  //   allowedRoles: [ROLES.ADMIN, ROLES.CONTRIBUTOR],
  //   exclude: true,
  // },
  {
    path: "/profile",
    component: Profile,
    exclude: true,
  },
];
