/*eslint-disable*/
import React from "react";

class Copyright extends React.Component {
  render() {
    return (
      <div className="copyright text-muted">
        © {new Date().getFullYear()}{" "}
        <a
          className="font-weight-bold ml-1"
          href="https://www.zevs.lv"
          rel="noopener noreferrer"
          target="_blank"
        >
          ZEVS
        </a>
      </div>
    );
  }
}

export default Copyright;
