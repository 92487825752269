import React from "react";

// constsants & types
import MODALS from "../constants/modals";
import { ICalendarDateModalProps } from "../types/Modal";

// utils
import { useTranslation } from "react-i18next";

// redux
import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

// components
import { Modal, Button, ListGroup, ListGroupItem } from "reactstrap";

export default function CalendarDateModal(props: ICalendarDateModalProps) {
  const { id, siteId, date, callback } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.choseAction")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <ListGroup>
          <ListGroupItem
            className="list-group-item-action"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                openModal({ type: MODALS.ScheduleVisitor, siteId, callback, initialDate: date })
              );
              close();
            }}
          >
            {t("texts.scheduleVisitor")}
          </ListGroupItem>
        </ListGroup>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
