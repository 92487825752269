import React, { useEffect, useState } from "react";

// utils
import { useTranslation } from "react-i18next";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getAccessLog } from "../../../actions/accessLog";

// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../components/LoadingOverlay";
import PaginationWithLimit from "../../../components/PaginationWithLimit";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";

// parts
import AccessLogRow from "./Row";

export default function AccessLog(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const accessLog = useSelector((state) => state.accessLog);

  const [paginationData, setPaginationData] = useState({
    page: accessLog.paginationData.page,
    limit: accessLog.paginationData.limit,
    timestamp: accessLog.paginationData.timestamp,
  })

  useEffect(() => {
    dispatch(
      getAccessLog({
        page: paginationData.page,
        limit: paginationData.limit,
      })
    );
  }, [
    paginationData.timestamp,
    paginationData.page,
    paginationData.limit,
  ]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-address-book mr-1" />
            {t("pages.accessLog")}
          </>
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={accessLog.isLoading}>
                <CardHeader className="border-0"></CardHeader>
                <Table
                  className="align-items-center table-flush table-hover"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th>{t("fields.user")}</th>
                      <th>{t("fields.ip")}</th>
                      <th>{t("fields.time")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessLog.docs.map((log, index) => (
                      <tr key={index}>
                        <AccessLogRow log={log} />
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <PaginationWithLimit
                    data={accessLog.paginationData}
                    onPageChange={(value) => {
                      setPaginationData((d) => ({...d, page: value}));
                    }}
                    onLimitChange = {(value) => {
                      setPaginationData((d) => ({...d, limit: value}));
                    }}
                  />
                </CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
