import { t } from "../helpers/common";

export enum USER_TYPES {
  LOCAL = "local",
  AZURE = "azure",
}

export const LIST = [
  {
    value: USER_TYPES.LOCAL,
    translationString: t("constants.userTypes.local"),
  },
  {
    value: USER_TYPES.AZURE,
    translationString: t("constants.userTypes.azure"),
  },
];
