import React, { useState } from "react";

// constants
import * as WORKFLOW_TYPES from "../constants/workflowTypes";

// utils
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios from "axios";
import workflowSchema from "../schemas/workflow";

// Redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";

// components
import { Formik } from "formik";
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

interface ITasksModalProps {
  modalId: string;
  siteId: string;
  data?: any; //TODO: task interface
  onChange: () => void;
}

export default function TaskModal(props: ITasksModalProps) {
  const { modalId, siteId } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (values: any) => {
    setSubmitting(true);
    axios
      .post("/api/tasks", values)
      .then((response) => {
        history.push(`/site/${siteId}/tasks/${response.data._id}`);
        close();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          name: "",
          site: siteId,
          type: WORKFLOW_TYPES.workflowTypes.Task,
        }}
        validationSchema={workflowSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">{t("modalTitles.newTask")}</h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
            </div>
            <div className="modal-footer">
              <SpinnerButton
                isLoading={submitting}
                type="submit"
                className="ml-auto"
                color="primary"
                disabled={!dirty}
              >
                {t("buttons.create")}
              </SpinnerButton>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
