import i18n from "../i18n";

export enum WorkflowExecutionStepsStatus {
  new = "new",
  inProgress = "in_progress",
  skipped = "skipped",
  finished = "finished",
}

export interface IWorkflowExecutionStepStatus {
  label: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

type WorklfowExecutionStepStatuses = {
  [key in WorkflowExecutionStepsStatus]: IWorkflowExecutionStepStatus;
};

export const WORKFLOW_EXECUTION_STEP_STATUSES: WorklfowExecutionStepStatuses = {
  [WorkflowExecutionStepsStatus.new]: {
    label: i18n.t("constants.workflowExecutionStep.new", "New"),
    color: "default",
  },
  [WorkflowExecutionStepsStatus.inProgress]: {
    label: i18n.t("constants.workflowExecutionStep.inProgress", "In progress"),
    color: "primary",
  },
  [WorkflowExecutionStepsStatus.finished]: {
    label: i18n.t("constants.workflowExecution.finished", "Finished"),
    color: "success",
  },
  [WorkflowExecutionStepsStatus.skipped]: {
    label: i18n.t("constants.workflowExecution.skipped", "Skipped"),
    color: "warning",
  },
};
