import React, { useState, useEffect } from "react";

// constants & types
import { useHistory } from "react-router-dom";
import { IPaginationDocs } from "../../../types/PaginationData";

// utils
import axios from "axios";
import { useTranslation } from "react-i18next";
import qs from "qs";
import _ from "lodash";
import { formatDatetime } from "../../../helpers/date";

// components
import paginationFactory from "react-bootstrap-table2-paginator";

import SimpleHeader from "../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// exports
import BootstrapTable from "react-bootstrap-table-next";
import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";
import { IQrCodeColleciton } from "../../../types/Qr";

const Events: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaginationDocs<IQrCodeColleciton>>({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
    sort: { field: "created", order: "desc" },
  });

  const onTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }: any
  ) => {
    switch (type) {
      case "sort":
        setData((d) => ({ ...d, page: 1, sort: { field: sortField, order: sortOrder } }));
        break;
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  const loadData = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    axios
      .get<IPaginationDocs<IQrCodeColleciton>>("/api/classifiers/qrCollection", {
        params: {
          page: data.page,
          limit: data.limit,
          sort: qs.stringify(data.sort),
        },
      })
      .then((response) => {
        setData((d) => ({ ...d, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, [data.limit, data.page, data.sort]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-qrcode mr-1" />
            {t("pages.qrCodes")}
          </>
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={loading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        id="tooltip443412080"
                        onClick={() => {
                          dispatch(
                            openModal({
                              type: MODALS.Qr,
                              callback: (qrCollectionId: string) => {
                                history.push(`/qr/${qrCollectionId}`);
                              },
                            })
                          );
                        }}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">{t("buttons.add")}</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip443412080">
                        {t("tooltips.addNewEvent")}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  defaultSorted={[
                    {
                      dataField: "created",
                      order: "desc",
                    },
                  ]}
                  columns={[
                    {
                      dataField: "name",
                      text: t("fields.name"),
                    },
                    {
                      dataField: "codes",
                      text: t("fields.codes"),
                      formatter: (codes) => {
                        return codes.length;
                      },
                      headerStyle: { width: 100 },
                    },
                    {
                      dataField: "created",
                      text: t("fields.created"),
                      sort: true,
                      formatter: (finishedAt) => {
                        return formatDatetime(finishedAt);
                      },
                      headerStyle: { width: 160 },
                    },
                  ]}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      history.push(`/qr/${row._id}`);
                    },
                  }}
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                />
                <CardFooter className="py-4"></CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Events;
