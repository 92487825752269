import {
  SET_EMPLOYEES,
  REPLACE_EMPLOYEE,
  PREPEND_EMPLOYEE,
  REMOVE_EMPLOYEE
} from "../../actions/types";

const initialState = {
  list: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        ...state,
        list: action.employees
      };
    case REPLACE_EMPLOYEE:
      return {
        ...state,
        list: state.list.map((employee, index) => {
          return action.employee._id === employee._id
            ? action.employee
            : employee;
        })
      };
    case PREPEND_EMPLOYEE:
      return {
        ...state,
        list: [action.employee, ...state.list]
      };
    case REMOVE_EMPLOYEE:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.id)
      };
    default:
      return state;
  }
}
