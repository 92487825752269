import * as yup from "yup";
// import { USER_ROLES } from "constants.js";

const userSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  password: yup
    .string()
    .required()
    .min(6),
  roles: yup.array().min(1),
  // .oneOf([])
});

export default userSchema;
