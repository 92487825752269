import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import uniqid from "uniqid";
import classnames from "classnames";

import { useDropzone } from "react-dropzone";
import { FormGroup } from "reactstrap";

export default function LabelMultipleFiles(props) {
  const { label, required, disabled, callback } = props;

  const { t } = useTranslation();
  const labelId = uniqid();

  const onDrop = useCallback((acceptedFiles) => {
    callback(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: disabled,
    maxFiles: 10,
    accept: "image/jpeg, image/png",
  });

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={labelId}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <div
        className={classnames(
          "form-control d-flex flex-column align-items-center justify-content-center",
          { active: isDragActive }
        )}
        {...getRootProps()}
        style={{ height: 100 }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>
            {t("placeholders.dropzoneActiveDrag", "Drop the files here...")}
          </span>
        ) : (
          <span>
            {t(
              "placeholders.dropzoneDefault",
              "Drag 'n' drop some files here, or click to select files"
            )}
          </span>
        )}
        <small>
          {t(
            "placeholders.dropzoneImages",
            "(Only *.jpeg and *.png images will be accepted)"
          )}
        </small>
      </div>
    </FormGroup>
  );
}
