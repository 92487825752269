import React from "react";

import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
} from "reactstrap";

function SiteOverall(props) {
  const { site } = props;

  return (
    <>
      <Row className="mb-4">
        <Col>
          <Card className="card-stats">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h5 className="h3 mb-0">{site.name}</h5>
                </Col>
                <Col className="text-right" xs="4">
                  <NavLink to={`/site/${site._id}/dashboard`}>
                    <Button
                      className="btn-link"
                      size="sm"
                      role="button"
                      style={{ border: 0 }}
                    >
                      <i className="fas fa-external-link-alt"></i>
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ListGroup flush>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <i className="fas fa-history text-info" />
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {site.events.length}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <i className="fas fa-key text-orange" />
                    </Col>
                    <Col style={{ textAlign: "right" }}></Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <i className="fas fa-truck text-default" />
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {site.transports.length}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <i className="fas fa-user-friends text-green" />
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {site.visitors.length}
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default SiteOverall;
