import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import ColorHash from "color-hash";

// components
import Skeleton from "react-loading-skeleton";
import { transportRoutes } from "../../helpers/apiRoutes";
import WidgetContainer from "../WidgetContainer";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { VisitorStatuses, LIST as VISITOR_STATUSES_LIST } from "../../constants/visitorStatuses";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface ITransportCount {
  _id: { _id: string; name: string; workspaceId: string };
  count: number;
}

interface IProps {
  widgetId: string;
  siteId: string;
  onDelete: (id: string) => any;
}

export default function TransportsToday({ widgetId, siteId, onDelete }: IProps) {
  const colorHash = new ColorHash();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITransportCount[]>([]);

  const { t } = useTranslation();

  const onRemove = () => {
    onDelete(widgetId);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get<ITransportCount[]>(transportRoutes.get.transportsToday(siteId))
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [widgetId]);

  return (
    <WidgetContainer title={t("widgets.transportsToday")} onRemove={onRemove}>
      {loading ? (
        <Skeleton height={35} />
      ) : (
        <Doughnut
          options={{
            plugins: {
              datalabels: {
                color: "#fff",
              },
            },
          }}
          data={{
            labels: data.map((value) => {
              return value._id.name;
            }),
            datasets: [
              {
                data: data.map((value) => value.count),
                backgroundColor: data.map((value) => colorHash.hex(value._id.name)),
                borderWidth: 1,
              },
            ],
          }}
        />
      )}
    </WidgetContainer>
  );
}
