import { t } from "../helpers/common";

export const LOCAL = "local";
export const AZURE = "azure";

export const LIST = [
  {
    value: LOCAL,
    translationString: t("constants.userTypes.local"),
  },
  {
    value: AZURE,
    translationString: t("constants.userTypes.azure"),
  },
];
