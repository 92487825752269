import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";

// utils
import { useTranslation } from "react-i18next";

// components
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";

import Item from "./Item";

export default function Collapsible(props) {
  const { route, onClick } = props;

  const { t } = useTranslation();
  const location = useLocation();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith(route.path)) {
      setOpen(true);
    }
  }, [route.path, location.pathname]);

  return (
    <NavItem>
      <NavLink
        to={route.path}
        onClick={(e) => {
          e.preventDefault();
          setOpen((e) => !e);
        }}
        tag={NavLinkRRD}
        data-toggle="collapse"
        aria-expanded={isOpen}
      >
        {route.icon}
        <span className="nav-link-text">{t(route.translationString)}</span>
      </NavLink>
      <div className="navbar-inner">
        <Collapse isOpen={isOpen}>
          <Nav navbar>
            {route.routes.map((subRoute, key) => {
              return <Item className="nav-indent" key={key} route={subRoute} onClick={onClick} />;
            })}
          </Nav>
        </Collapse>
      </div>
    </NavItem>
  );
}
