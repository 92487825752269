import * as yup from "yup";
// import moment from "moment";

const visitorSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .min(3),
  lastName: yup
    .string()
    .required()
    .min(3),
  arrived: yup.date("validation.invalidDate").required("validation.dateRequired"),
  // .max(moment().add(1, "minute")),

  left: yup.date("validation.invalidDate").nullable(),
  notes: yup.string().max(1000),
});

export default visitorSchema;
