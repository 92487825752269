import axios from "axios";

import * as scopes from "./scopes";

// actions
import { setPaginationData, setLoading } from "./common";

import { SET_WORKSPACES } from "./types";

const setWorkspaces = (payload) => {
  return {
    type: SET_WORKSPACES,
    payload,
  };
};

export const getWorkspaces = (data) => (dispatch) => {
  dispatch(setLoading(scopes.WORKSPACES, true));
  return axios
    .post("/api/workspaces/get", data)
    .then((res) => {
      const { docs, ...paginationData } = res.data;
      dispatch(setWorkspaces(docs));
      dispatch(setPaginationData(scopes.WORKSPACES, paginationData));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    })
    .finally(() => {
      dispatch(setLoading(scopes.WORKSPACES, false));
    });
};

export const getAllWorkspaces = (data) => (dispatch) => {
  dispatch(setLoading(scopes.WORKSPACES, true));
  return axios
    .post("/api/workspaces/getAll", data)
    .then((res) => {
      const { docs, ...paginationData } = res.data;
      dispatch(setWorkspaces(docs));
      dispatch(setPaginationData(scopes.WORKSPACES, paginationData));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    })
    .finally(() => {
      dispatch(setLoading(scopes.WORKSPACES, false));
    });
};
