import React, { useState, useEffect, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

interface IProps {
  id: string;
  widgetElements: ReactNode,
  onRemove: (id: string) => void;
}

export default function WorkflowExecutionCount( { id, widgetElements, onRemove }: IProps) {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { t } = useTranslation();
    
    return(
        <Card>            
          <div className="d-flex align-items-baseline ml-auto pt-1 pr-1">
            <Dropdown
              direction="right"
              isOpen={isDropdownOpen}
              toggle={() => {
                setDropdownOpen((e) => !e);
              }}
            >
              <DropdownToggle className="btn-link hover btn-sm mr-0 ml-0">
                <i className="fas fa-ellipsis-h text-muted"/>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    onRemove(id);
                  }}
                >
                  <i className="fas fa-trash"/>
                  {t("buttons.removeWidget")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <CardBody className="pt-0">
            {widgetElements}
          </CardBody>
        </Card>
    );
}