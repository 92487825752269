import * as yup from "yup";

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(6),
  passwordRepeat: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

export default passwordSchema;
