import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import _ from "lodash";
import { useSelector } from "react-redux";

// layouts
import Layout from "./layouts/Layout";
import SidebarLayout from "./layouts/Sidebar";
import { ToastContainer } from "react-toastify";

// Routes
import ownerRoutes from "./routes/owner";
import allRoutes from "./routes/admin";

// pages
import Login from "./views/Login";
import DataLoader from "./components/DataLoader";

export default function App() {
  const auth = useSelector((state) => state.auth);

  const [routes, setRoutes] = useState([]);
  const [match, setMatch] = useState({ match: { path: "" } });

  useEffect(() => {
    if (auth.isAuthenticated) {
      const script = document.createElement("script");

      script.src =
        "https://devlars.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-ipn83r/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=cce2e0ba";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (auth.user._id) {
      if (auth.user.owner) {
        setRoutes(ownerRoutes);
      } else {
        const modules = auth.user.modules.map((module) => module.value);
        setRoutes(
          allRoutes.filter((route) => {
            var allowAccess = true;
            if (route.allowedRoles) {
              if (
                route.allowedRoles.filter((value) => auth.user.systemRoles.includes(value))
                  .length === 0
              ) {
                allowAccess = false;
              }
            }
            if (route.allowedModules) {
              if (_.intersection(route.allowedModules, modules).length === 0) {
                allowAccess = false;
              }
            }

            return allowAccess;
          })
        );
      }
    } else {
      setRoutes([]);
    }
  }, [auth.user]);

  const setProps = (props) => {
    if (props.match.path !== match.match.path) {
      setMatch(props);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" hideProgressBar />
      {auth.isAuthenticated ? (
        <DataLoader>
          <BrowserRouter>
            <SidebarLayout routes={routes} match={match} />
            <Switch>
              {routes.map((route, key) => {
                if (route.routes) {
                  return route.routes.map((subRoute, subKey) => {
                    return (
                      <Route
                        key={`${key}.${subKey}`}
                        exact
                        path={subRoute.path}
                        render={(props) => {
                          setProps(props);
                          return (
                            <Layout {...props} routes={routes} component={subRoute.component} />
                          );
                        }}
                      />
                    );
                  });
                } else {
                  return (
                    <Route
                      key={key}
                      exact
                      path={route.path}
                      render={(props) => {
                        setProps(props);
                        return <Layout {...props} routes={routes} component={route.component} />;
                      }}
                    />
                  );
                }
              })}
            </Switch>
          </BrowserRouter>
        </DataLoader>
      ) : (
        <Login />
      )}
    </>
  );
}
