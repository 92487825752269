import React, { useEffect, useState } from "react";

// constants & types
import { IWorkflowSitesModalProps } from "../types/Modal";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

// components
import { Button, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";

import { ISite } from "../types/Site";
import LabelTypeahead from "../components/Inputs/LabelTypeahead";

import LoadingOverlay from "../components/LoadingOverlay";

export default function WorklfowSites(props: IWorkflowSitesModalProps) {
  const { id, workflowId } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<ISite[]>([]);
  const [selectedSite, setSelectedSite] = useState<ISite>();

  const loadSites = () => {
    setLoading(true);
    axios
      .get<ISite[]>(`/api/sites/all`)
      .then((res) => {
        console.log("all:", res.data);

        setLoading(false);
        setSites(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  };

  useEffect(() => {
    if (selectedSite) {
      axios
        .post<ISite>(`/api/sites/${selectedSite._id}/addWorkflow`, { workflowId })
        .then((res) => {
          setSelectedSite(undefined);
          setSites((s) => s.map((site) => (site._id === res.data._id ? res.data : site)));
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, [selectedSite]);

  useEffect(() => {
    loadSites();
  }, []);

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workflowSites")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <LoadingOverlay isLoading={loading}>
          <LoadingOverlay isLoading={Boolean(selectedSite)}>
            <LabelTypeahead
              label={t("fields.sites")}
              options={sites.filter((site) => !site.workflows.includes(workflowId))}
              labelKey={(option: ISite) => {
                return option.name;
              }}
              onChange={(value: ISite[]) => {
                setSelectedSite(value[0]);
              }}
              selected={selectedSite ? [selectedSite] : []}
            />
          </LoadingOverlay>
          <ListGroup>
            {sites
              .filter((site) => site.workflows.includes(workflowId))
              .map((site) => (
                <SiteListItem
                  key={site._id}
                  site={site}
                  workflowId={workflowId}
                  deleteCallback={(updatedSite) => {
                    setSites((s) =>
                      s.map((site) => (site._id === updatedSite._id ? updatedSite : site))
                    );
                  }}
                />
              ))}
          </ListGroup>
        </LoadingOverlay>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}

interface ISiteListItemProps {
  site: ISite;
  workflowId: string;
  deleteCallback: (site: ISite) => void;
}

const SiteListItem: React.FunctionComponent<ISiteListItemProps> = ({
  site,
  workflowId,
  deleteCallback,
}) => {
  const [deleting, setDeleting] = useState(false);

  const onDelete = () => {
    setDeleting(true);
    axios
      .delete<ISite>(`/api/sites/${site._id}/workflow/${workflowId}`)
      .then((res) => {
        deleteCallback(res.data);
      })
      .catch((err) => {
        setDeleting(false);
      });
  };

  return (
    <ListGroupItem className="d-flex justify-content-between align-items-center">
      <Container>
        <Row className="align-items-center">
          <Col xs>
            <h4 className="mb-0">{site.name}</h4>
            <small>{site.address}</small>
          </Col>
          <Col className="col-auto">
            <SpinnerButton
              className="btn-icon btn-2"
              size="sm"
              isLoading={deleting}
              onClick={onDelete}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-times" />
              </span>
            </SpinnerButton>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>
  );
};
