import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import ColorHash from "color-hash";

// components
import Skeleton from "react-loading-skeleton";
import { visitorRoutes } from "../../helpers/apiRoutes";
import WidgetContainer from "../WidgetContainer";

import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IVisitorCount {
  _id: string;
  count: number;
}

interface IProps {
  widgetId: string;
  siteId: string;
  onDelete: (id: string) => any;
}

let weekDates: Date[] = [];
for (let day = 6; day >= 0; day--) {
  weekDates.push(
    moment()
      .subtract(day, "days")
      .toDate()
  );
}

export default function WorkflowExecutionCount({ widgetId, siteId, onDelete }: IProps) {
  const colorHash = new ColorHash();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IVisitorCount[]>([]);

  const { t } = useTranslation();

  const onRemove = () => {
    onDelete(widgetId);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get<IVisitorCount[]>(visitorRoutes.get.visitorsWeek(siteId))
      .then((res) => {
        setData(
          _.sortBy(
            res.data,
            (object) => {
              return moment(object._id, "DD.MM.YYYY.").toDate();
            },
            ["asc"]
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [widgetId]);

  return (
    <WidgetContainer title={t("widgets.visitorsWeek")} onRemove={onRemove}>
      {loading ? (
        <Skeleton height={35} />
      ) : (
        <Bar
          options={{
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                color: "#fff",
                display: function(context) {
                  return context.dataset.data[context.dataIndex] !== 0;
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  stepSize: 1,
                },
              },
            },
          }}
          data={{
            labels: weekDates.map((date) => moment(date).format("DD.MM.")),
            datasets: [
              {
                data: weekDates.map((date) => {
                  let visitors = data.find((d) => moment(d._id, "DD.MM.YYYY.").isSame(date, "day"));
                  if (visitors) {
                    return visitors.count;
                  } else {
                    return 0;
                  }
                }),
                backgroundColor: data.map((value) => colorHash.hex("visitors")),
              },
            ],
          }}
        />
      )}
    </WidgetContainer>
  );
}
