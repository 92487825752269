import React from "react";

import _ from "lodash";

import { Container, Row, Col, Badge } from "reactstrap";
import { ExecutionStep, IExecution } from "../../../../types/WorkflowExecution";
import { WORKFLOW_STEPS } from "../../../../constants/workflowSteps";
import { WORKFLOW_EXECUTION_STEP_STATUSES } from "../../../../constants/workflowExecutionStepsStatuses";
import classnames from "classnames";
import moment from "moment";
import ExecutionData from "./data";

interface IExecutionStepsProps {
  execution: IExecution;
}

const ExecutionSteps: React.FC<IExecutionStepsProps> = ({ execution }) => {
  return (
    <Container fluid>
      {_.sortBy(execution.workflowSteps, "order").map((step, index) => {
        return (
          <Row
            key={step._id}
            style={{
              paddingBottom: 8,
            }}
          >
            <Col>
              <StepHeader step={step} />
              <ExecutionData step={step} />
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

const StepHeader: React.FC<{ step: ExecutionStep }> = ({ step }) => {
  return (
    <Row
      className="mb-2"
      style={{
        alignItems: "center",
      }}
    >
      <Col xs="auto">
        <div
          className={classnames(`icon icon-shape text-white rounded-circle shadow`)}
          style={{
            backgroundColor: WORKFLOW_STEPS[step.type].color,
          }}
        >
          <i className={`fas ${WORKFLOW_STEPS[step.type].icon}`} />
        </div>
      </Col>
      <Col>
        <h5 className="mb-0">
          {step.name}{" "}
          {step.status ? (
            <Badge color={WORKFLOW_EXECUTION_STEP_STATUSES[step.status].color}>
              {WORKFLOW_EXECUTION_STEP_STATUSES[step.status].label}
            </Badge>
          ) : null}
        </h5>
        {step.finishedAt ? (
          <p className="text-sm mb-0">{moment(step.finishedAt).format("L LT")}</p>
        ) : null}
      </Col>
    </Row>
  );
};

export default ExecutionSteps;
