import axios from "axios";
import {
  SET_TRANSPORT_TYPES,
  PREPEND_TRANSPORT_TYPE,
  REPLACE_TRANSPORT_TYPE,
  REMOVE_TRANSPORT_TYPE,
} from "../types";

import { closeModal } from "../../actions/modals";
import { TRANSPORT_TYPES_MODAL } from "../../modals/types";

export const setTransportTypes = (transportTypes) => {
  return {
    type: SET_TRANSPORT_TYPES,
    payload: transportTypes,
  };
};

export const getTransportTypes = () => (dispatch) => {
  axios
    .post("/api/classifiers/transportTypes/get")
    .then((res) => {
      dispatch(setTransportTypes(res.data));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

export const createTransportType = (data) => (dispatch) => {
  return axios
    .post("/api/classifiers/transportTypes/create", data)
    .then((res) => {
      dispatch(closeModal(TRANSPORT_TYPES_MODAL));
      dispatch(prependTransportType(res.data));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

const prependTransportType = (transportType) => {
  return {
    type: PREPEND_TRANSPORT_TYPE,
    payload: transportType,
  };
};

const replaceTransportType = (transportType) => {
  return {
    type: REPLACE_TRANSPORT_TYPE,
    payload: transportType,
  };
};

export const updateTransportType = (data) => (dispatch) => {
  return axios
    .post("/api/classifiers/transportTypes/update", data)
    .then((res) => {
      dispatch(closeModal(TRANSPORT_TYPES_MODAL));
      dispatch(replaceTransportType(res.data));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

const removeTransportType = (_id) => {
  return {
    type: REMOVE_TRANSPORT_TYPE,
    payload: _id,
  };
};

export const deleteTransportType = (_id) => (dispatch) => {
  return axios
    .post("/api/classifiers/transportTypes/delete", { _id })
    .then((res) => {
      dispatch(removeTransportType(_id));
      dispatch(closeModal(TRANSPORT_TYPES_MODAL));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};
