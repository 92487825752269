import { t } from "../helpers/common";

export enum workflowGeolocationTypes {
  Single = "single",
  All = "all",
  AtLeaseOne = "at_least_one",
  Any = "any",
}

interface IWorkflowGeolocationType {
  value: workflowGeolocationTypes;
  translationString: string;
}

export const SCAN_SINGLE: IWorkflowGeolocationType = {
  value: workflowGeolocationTypes.Single,
  translationString: t("constants.workflowGeolocationTypes.single", "Single"),
};

export const SCAN_ALL: IWorkflowGeolocationType = {
  value: workflowGeolocationTypes.All,
  translationString: t("constants.workflowGeolocationTypes.all", "All"),
};

export const SCAN_AT_LEAST_ONE: IWorkflowGeolocationType = {
  value: workflowGeolocationTypes.AtLeaseOne,
  translationString: t("constants.workflowGeolocationTypes.atLeastOne", "At least one"),
};

export const SCAN_ANY: IWorkflowGeolocationType = {
  value: workflowGeolocationTypes.Any,
  translationString: t("constants.workflowGeolocationTypes.any", "Any"),
};

export const LIST: IWorkflowGeolocationType[] = [
  SCAN_SINGLE,
  SCAN_ALL,
  SCAN_AT_LEAST_ONE,
  SCAN_ANY,
];
