import * as yup from "yup";

const siteSchema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  contactNumber: yup.string().required(),
  contactName: yup.string().required(),
  contactEmail: yup
    .string()
    .required()
    .email()
});

export default siteSchema;
