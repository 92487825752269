import React from "react";

// utils
import uniqid from "uniqid";

// components
import { FormGroup, Input } from "reactstrap";

class LabelSelectFormGroup extends React.Component {
  constructor() {
    super();
    this.labelId = uniqid();
  }

  render() {
    const {
      label,
      placeholder,
      touched,
      validationErrors,
      options,
      getOptionValue,
      required,
      ...props
    } = this.props;

    const invalid = touched && (validationErrors ? true : false);

    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={this.labelId}>
          {label}
          {required ? <span className="text-danger">*</span> : null}
        </label>
        <Input id={this.labelId} type="select" invalid={invalid} {...props}>
          <option value="">{placeholder ? placeholder : label}</option>
          {options.map((option, index) => (
            <option key={index} value={getOptionValue ? getOptionValue(option) : option.value}>
              {option.name}
            </option>
          ))}
        </Input>
        {invalid ? <div className="invalid-feedback">{validationErrors}</div> : null}
      </FormGroup>
    );
  }
}

// TODO: proptypes

export default LabelSelectFormGroup;
