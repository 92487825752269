import React, { useEffect, useState } from "react";

interface IEditableText {
    value: string,
    onSubmit:(value: string) => void,
}

export default function EditableText({value, onSubmit}: IEditableText) {

    const [inputValue, setInputValue] = useState<string>(value);
    const [recentValue, setRecentValue] = useState<string>(value);

    useEffect(() => {
        setInputValue(value);
        setRecentValue(value);
    }, [value])

    return(
        <span 
            className="input form-control editable" 
            role="textbox"
            spellCheck="false"
            contentEditable
            suppressContentEditableWarning={true}
            onKeyDown={(e) => {
                if(e.key === "Enter"){
                    e.currentTarget.blur();
                }
            }}
            onInput={(e) => {
                if(e.currentTarget.innerText.length >= 51){
                    e.currentTarget.innerText = inputValue;
                }else{
                    setInputValue(e.currentTarget.innerText);
                }
            }}
            onBlur={() => {
                if(recentValue !== inputValue){
                    setRecentValue(inputValue);
                    onSubmit(inputValue);
                }
            }}
        >
        {recentValue}
        </span>
    );
}
