import {
  SET_TRANSPORT_TYPES,
  REPLACE_TRANSPORT_TYPE,
  PREPEND_TRANSPORT_TYPE,
  REMOVE_TRANSPORT_TYPE
} from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSPORT_TYPES:
      return action.payload;
    case PREPEND_TRANSPORT_TYPE:
      return [action.payload, ...state];
    case REPLACE_TRANSPORT_TYPE:
      return state.map(transportType => {
        return action.payload._id === transportType._id
          ? action.payload
          : transportType;
      });
    case REMOVE_TRANSPORT_TYPE:
      return state.filter(item => item._id !== action.payload);
    default:
      return state;
  }
}
