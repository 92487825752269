import React, { useState } from "react";

// constants & types
import { IQrModalProps } from "../types/Modal";
import { MODULES } from "../constants/modules";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import qrCodeCollectionSchema from "../schemas/qrCodeCollection";

// components
import { Button, Form, Modal } from "reactstrap";
import { Formik } from "formik";

import PermissionGuard from "../components/PermissionGuard";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import { IQrCodeCollecitonBase } from "../types/Qr";

interface IQrRequest extends IQrCodeCollecitonBase {
  count: number;
}

const initialValues: IQrRequest = {
  name: "",
  count: 10,
};

export default function QrCollectionModal(props: IQrModalProps) {
  const { id, callback } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (values: IQrRequest) => {
    setSubmitting(true);
    axios
      .post<string>("/api/classifiers/qrCollection", values)
      .then((response) => {
        callback(response.data);
        close();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={initialValues}
        validationSchema={qrCodeCollectionSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">{t("modalTitles.createQrCodeCollection")}</h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
              <LabelFormGroup
                label={t("fields.count")}
                name="count"
                type="number"
                value={values.count}
                onChange={handleChange}
                touched={touched.count}
                validationErrors={errors.count}
              />
            </div>
            <div className="modal-footer">
              <PermissionGuard module={MODULES.Workflows} permission={ROLE_PERMISSIONS.WRITE}>
                {({ hasAccess }) => (
                  <SpinnerButton
                    isLoading={submitting}
                    disabled={!hasAccess}
                    type="submit"
                    className="ml-auto"
                    color="primary"
                  >
                    {t("buttons.create")}
                  </SpinnerButton>
                )}
              </PermissionGuard>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
