import React, { useState } from "react";

// utils
import _ from "lodash";

import { useDispatch } from "react-redux";

// reactstrap components
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useEffect } from "react";
import { setPaginationData } from "../actions/common";

const LEFT_PAGE = "<..";
const RIGHT_PAGE = "..>";

const pageNeighbours = 2;

const totalNumbers = pageNeighbours * 2 + 3;

export default function CustomPagination(props) {
  const { data, scope, onChange } = props;

  const dispatch = useDispatch();

  const [range, setRange] = useState([]);

  const changePage = (page) => {
    if (typeof onChange === "function") {
      onChange(page);
    } else {
      dispatch(setPaginationData(scope, { page }));
    }
  };

  useEffect(() => {
    let tmp = [];

    if (data.totalPages > totalNumbers) {
      const startPage = Math.max(2, data.page - pageNeighbours);
      const endPage = Math.min(data.totalPages - 1, data.page + pageNeighbours);

      let pages = _.range(startPage, endPage + 1);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = data.totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = _.range(startPage - spillOffset, startPage);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = _.range(endPage + 1, endPage + spillOffset + 1);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      tmp = [1, ...pages, data.totalPages];
    } else {
      tmp = _.range(1, data.totalPages + 1);
    }

    setRange(tmp);
  }, [data]);

  return (
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={data.hasPrevPage ? null : "disabled"}>
          <PaginationLink href="#" onClick={() => changePage(data.prevPage)} tabIndex="-1">
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {range.map((page, index) => {
          return (
            <PaginationItem
              key={`paginationItem${index}`}
              className={data.page === page ? "active" : ""}
            >
              <PaginationLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();

                  switch (page) {
                    case LEFT_PAGE:
                      changePage(range[index + 1] - 1);
                      break;
                    case RIGHT_PAGE:
                      changePage(range[index - 1] + 1);
                      break;
                    default:
                      changePage(page);
                      break;
                  }
                }}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem className={data.hasNextPage ? null : "disabled"}>
          <PaginationLink href="#" onClick={() => changePage(data.nextPage)}>
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
}
