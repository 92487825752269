import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";

// components
import SimpleHeader from "../../components/Headers/SimpleHeader.jsx";
import { usersRoutes } from "../../helpers/apiRoutes";
import { Container, Row, Col } from "reactstrap";
import SiteOverall from "../../components/Widgets/SiteOverall";
import ActionItems, {ActionItemTypes, Triggers} from "../../components/ActionItems";
import UserActions from "../../components/Widgets/UserActions";
import * as WIDGETS from "../../constants/widgetsDashboard";
import { openModal } from "../../actions/modals";
import { ADD_WIDGET_DASHBOARD_MODAL } from "../../modals/types";

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sites, setSites] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const onSubmitCallback = () => {
    axios
    .post("/api/common/getOveralls")
    .then((res) => {
      setSites(res.data);
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
  }
  const onWidgetAddCallback = () => {
    axios
      .get(usersRoutes.get.widget)
      .then((res) => {
        setWidgets(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCorespondingWidget = (widget) => {
    switch (widget.type) {
      case WIDGETS.WidgetTypes.user_actions:
        return (
          <UserActions
            widgetId={widget._id}
            onDelete={handleRemove}
          />
        );
      default:
        return (
          console.error(`${widget.type} is added but won't render`)
        );
    }
  }

  const handleRemove = (widgetId) => {
    axios
      .delete(usersRoutes.delete.widget(widgetId))
      .then((res) => {
        onWidgetAddCallback();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onSubmitCallback();
    onWidgetAddCallback();
  }, []);

  return (
    <>
      <SimpleHeader 
          name={t("pages.dashboard")} 
          button={
            <ActionItems
              elements={[
                {
                  icon: 'plus',
                  tooltip: t("buttons.addWidget"),
                  type: ActionItemTypes.Button,
                  click: () =>
                    dispatch(
                      openModal(ADD_WIDGET_DASHBOARD_MODAL, {
                        onWidgetAddCallback,
                      })
                    ),
                },
              ]}
              trigger={Triggers.Click}
              icon={<i className="fas fa-cog"/>}
              link={false}
              count={0}
            />
          }
      />
      <Container className="mt--6" fluid>
        <Row>
          {sites.map((site, index) => {
            return (
              <Col key={index} lg="3" sm="4">
                <SiteOverall site={site} />
              </Col>
            );
          })}
        </Row>
          <Col md="6" lg="4" sm="12">
            {widgets.map((widget, index) => {
              return (
                  getCorespondingWidget(widget)
              );
            })}
          </Col>
      </Container>
    </>
  );
}

export default Dashboard;
