import React, { useEffect, useState } from "react";

// constants & types
import { LIST as SYSTEM_ROLE_LIST } from "../../constants/roles";
import { LIST as MODULE_LIST } from "../../constants/modules";
import MODALS from "../../constants/modals";
import { IRole } from "../../types/Role";
import { IModule } from "../../types/Module";

// utils
import _ from "lodash";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/redux";
import { openModal } from "../../features/modals";

// components
import SimpleHeader from "../../components/Headers/SimpleHeader";

import BootstrapTable from "react-bootstrap-table-next";
import { Button, Card, CardHeader, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

const Roles = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);

  const loadData = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    axios
      .get<IRole[]>("api/classifiers/roles")
      .then((response) => {
        setRoles(response.data);
        setLoading(false);
        console.log("response:", response);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-user-tag" />
            {t("pages.roles")}
          </>
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col className="text-right" xs={12}>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      id="tooltip443412080"
                      onClick={() =>
                        dispatch(openModal({ type: MODALS.Role, onSuccess: loadData }))
                      }
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">{t("buttons.create")}</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip443412080">
                      {t("tooltips.createRole")}
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </CardHeader>
              <BootstrapTable
                keyField="_id"
                bootstrap4
                hover
                data={roles}
                remote
                columns={[
                  {
                    dataField: "name",
                    text: t("fields.name"),
                    formatter: (name, row: IRole) => {
                      if (row.system) {
                        const systemRole = SYSTEM_ROLE_LIST.find((role) => role.value === row.name);

                        if (systemRole) {
                          return (
                            <div style={{ whiteSpace: "nowrap" }}>
                              {t(systemRole.translationString)}
                              <i
                                id={systemRole.value}
                                className="fas fa-info-circle text-info pl-1"
                              />
                              <UncontrolledTooltip
                                placement="right"
                                delay={0}
                                target={systemRole.value}
                              >
                                {t(systemRole.tooltipString)}
                              </UncontrolledTooltip>
                            </div>
                          );
                        }
                      }
                      return name;
                    },
                  },
                  {
                    dataField: "modules",
                    text: t("fields.modules"),
                    formatter: (modules: IModule[], row: IRole) => {
                      if (row.system) {
                        return "-";
                      }

                      return _.join(
                        modules
                          .filter((module) => module.permission)
                          .map((module) => {
                            const systemModule = MODULE_LIST.find((m) => m.value === module.value);

                            if (systemModule) {
                              return t(systemModule.translationString);
                            }
                          }),
                        ", "
                      );
                    },
                    headerStyle: { width: "100%" },
                  },
                ]}
                rowEvents={{
                  onClick: (e, row: IRole, rowIndex) => {
                    if (!row.system) {
                      dispatch(
                        openModal({
                          type: MODALS.Role,
                          role: row,
                          onSuccess: loadData,
                          onDelete: loadData,
                        })
                      );
                    }
                  },
                }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Roles;
