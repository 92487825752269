import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// constants & types
import * as VISITOR_STATUSES from "../../constants/visitorStatuses";
import { IVisitorFilters } from "../../types/Visitor";

// utils
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

// reactstrap components
import { Collapse, Card, Row, Col, CardBody, Button } from "reactstrap";

// components
import LabelDatePickerFormGroup from "../../components/Inputs/LabelDatePickerFormGroup";
import LabelSelectFormGroup from "../../components/Inputs/LabelSelectFormGroup";

interface IVisitorFiltersProps {
  onChange: (filters: IVisitorFilters | undefined) => void;
  isOpen: boolean;
}

function VisitorsFilters(props: IVisitorFiltersProps) {
  const { onChange, isOpen } = props;

  const { t } = useTranslation();

  const [data, setData] = useState<IVisitorFilters>();

  useEffect(() => {
    console.log("data:", data);
    onChange(data);
    // onChange(_.omitBy(_.omitBy(data, _.isNil), _.isEmpty));
  }, [data]);

  return (
    <Collapse isOpen={isOpen}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={6} md={6}>
              <Row>
                <Col xs={6}>
                  <LabelDatePickerFormGroup
                    label={t("fields.arrived")}
                    placeholder={t("fields.dateFrom")}
                    key={!data || !data.arrivedFrom ? uuidv4() : null}
                    value={data && data.arrivedFrom}
                    onChange={(date: Date) =>
                      setData((d) => ({ ...d, arrivedFrom: moment(date).toDate() }))
                    }
                  />
                </Col>
                <Col xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                  <LabelDatePickerFormGroup
                    placeholder={t("fields.dateTo")}
                    key={!data || !data.arrivedTo ? uuidv4() : null}
                    value={data && data.arrivedTo}
                    onChange={(date: Date) =>
                      setData((d) => ({ ...d, arrivedTo: moment(date).toDate() }))
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6} md={6}>
              <Row>
                <Col xs={6}>
                  <LabelDatePickerFormGroup
                    label={t("fields.left")}
                    placeholder={t("fields.dateFrom")}
                    key={!data || !data.leftFrom ? uuidv4() : null}
                    value={data && data.leftFrom}
                    onChange={(date: Date) =>
                      setData((d) => ({ ...d, leftFrom: moment(date).toDate() }))
                    }
                  />
                </Col>
                <Col xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                  <LabelDatePickerFormGroup
                    placeholder={t("fields.dateTo")}
                    key={!data || !data.leftTo ? uuidv4() : null}
                    value={data && data.leftTo}
                    onChange={(date: Date) =>
                      setData((d) => ({ ...d, leftTo: moment(date).toDate() }))
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <LabelSelectFormGroup
                label={t("fields.status")}
                placeholder={t("placeholders.chose")}
                value={data && data.status ? data.status : ""}
                options={VISITOR_STATUSES.LIST.map((status) => ({
                  value: status.value,
                  name: t(status.translationString),
                }))}
                onChange={(e: any) => {
                  setData((d) => ({ ...d, status: e.target.value }));
                }}
              />
            </Col>
            <Button
              className="btn-sm align-self-end ml-auto mr-2"
              onClick={() => setData(undefined)}
            >
              {t("fields.clear")}
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
}

VisitorsFilters.propTypes = {
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default VisitorsFilters;
