import React, { useEffect, useState } from "react";

import { closeModalById } from "../actions/modals";

import { useTranslation } from "react-i18next";
import { usersRoutes } from "../helpers/apiRoutes";
import { useDispatch, useSelector } from "react-redux";
import * as WIDGETS from "../constants/widgetsDashboard";

// components
import { Modal, Button, Row, Col, ListGroup ,ListGroupItem } from "reactstrap";
import axios from "axios";

interface IProps {
    modalId: string;
    siteId: string;
    onWidgetAddCallback(): void;
  }

export default function AddWidgetDashboardModal( props: IProps) {
    const { modalId, onWidgetAddCallback } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModalById(modalId));
    };

    const onSubmit = (type: string) => {
        axios
        .post(usersRoutes.post.widget, {type: type})
        .then((res) => {
          onWidgetAddCallback();
          close();
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
            <div className="modal-header">
                <h6 className="modal-title">{t("modalTitles.addWidget")}</h6>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                    <ListGroup>
                        {WIDGETS.LIST.map((widget) => (
                            <ListGroupItem
                            action
                            onClick={() => {
                                onSubmit(widget.value);
                            }}>
                            <Row className="align-items-center">
                                <Col className=" col-auto">
                                    {t(widget.translationString)}
                                </Col>
                            </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
            </div>
            <div className="modal-footer">
                <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
                </Button>
            </div>
        </Modal>
    );
}