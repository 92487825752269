import React from "react";

// interfaces
import { FormikErrors, FormikTouched } from "formik";
import { IFormFieldCheckbox } from "../../../../../constants/formFields";

// utils
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

// components
import { Col, Row } from "reactstrap";
import LabelFormGroup from "../../../../../components/Inputs/LabelFormGroup";

interface IInputItemProps {
  values: IFormFieldCheckbox;
  index: number;
  touched: FormikTouched<IFormFieldCheckbox>;
  errors: FormikErrors<IFormFieldCheckbox>;
  setFieldValue: ISetFieldValue;
}

interface ISetFieldValue {
  (field: string, value: any, shouldValidate?: boolean | undefined): void;
}

export default function CheckboxItem({
  index,
  values,
  touched,
  errors,
  setFieldValue,
}: IInputItemProps) {
  const { t } = useTranslation();

  const requiredLabelId = uniqid();

  return (
    <>
      <Row>
        <Col>
          <LabelFormGroup
            label={t("fields.fieldName")}
            value={values.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`fields.${index}.name`, e.target.value);
            }}
            touched={touched ? touched.name : false}
            validationErrors={errors ? errors.name : null}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="custom-control custom-control-alternative custom-checkbox">
            <input
              className="custom-control-input"
              id={requiredLabelId}
              type="checkbox"
              checked={values.required}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`fields.${index}.required`, e.target.checked);
              }}
            />
            <label className="custom-control-label" htmlFor={requiredLabelId}>
              <span className="text-muted">{t("fields.required")}</span>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
}
