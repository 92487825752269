import React from "react";
import { IQrValue } from "../../../../../types/WorkflowExecution";

// utils
import { useAppDispatch } from "../../../../../hooks/redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// components
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { Button } from "reactstrap";
import { openModal } from "../../../../../features/modals";
import MODALS from "../../../../../constants/modals";
import { ILocationCoords } from "../../../../../types/common";
import { IChecklistItem } from "../../../../../types/ChecklistItem";

interface Props {
  items?: IChecklistItem[];
}

export default function ChecklistStepData({ items }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <BootstrapTable
      keyField="_id"
      data={items ? items : []}
      columns={[
        {
          dataField: "name",
          text: t("fields.name"),
        },
        {
          dataField: "checkedAt",
          text: t("fields.checkedAt"),
          formatter: (scannedAt) => {
            return moment(scannedAt).format("L LT");
          },
          headerStyle: { width: 200 },
        },
        {
          dataField: "checked",
          text: t("fields.checked"),
          formatter: (checked) => {
            return checked ? <i className="fas fa-check" /> : "";
          },
          align: "center",
          headerStyle: { width: 50 },
        },
        {
          dataField: "location",
          text: "",
          headerFormatter: () => {
            if (!items || !items.find((item) => item.location)) {
              return "";
            }
            return (
              <Button
                className="btn-neutral btn-icon"
                size="sm"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Map,
                      markers: items
                        .filter((item) => item.location)
                        .map((item) => {
                          return {
                            location: item.location as ILocationCoords,
                          };
                        }),
                    })
                  );
                }}
              >
                <i className="fas fa-map-marked-alt"></i>
              </Button>
            );
          },
          formatter: (location) => {
            if (!location) {
              return "";
            }
            return (
              <Button
                className="btn-neutral btn-icon"
                size="sm"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Map,
                      markers: [
                        {
                          location: location,
                        },
                      ],
                    })
                  );
                }}
              >
                <i className="fas fa-map-marked-alt"></i>
              </Button>
            );
          },
          headerStyle: { width: 50 },
        },
      ]}
    />
  );
}
