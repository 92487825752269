import React, { useState, useEffect } from "react";

import { RouteComponentProps } from "react-router-dom";

// constants & types
import {
  IWorkflow,
  WorkflowSteps,
  Workflow,
  IChildWorkflow,
  IChainedWorkflow,
} from "../../../types/Workflow";

// utils
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router";
import axios from "axios";

//Redux
import { useAppDispatch } from "../../../hooks/redux";
import { WORKFLOW_ADD_STEP, APPROVE_DIALOG_MODAL } from "../../../modals/types";
// TODO: remove openModal usage
import { openModal } from "../../../actions/modals";
import { openModal as featuresOpenModal } from "../../../features/modals";

// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";

import { Card, Container, Row, CardHeader, Col, UncontrolledTooltip } from "reactstrap";
import LoadingList from "./LoadingList";
import { ListGroupItem } from "reactstrap/lib";
import EditableText from "../../../components/EditableText";
import ActionItems, { ActionItemTypes, Triggers } from "../../../components/ActionItems";

// Parts
import ListGroup from "reactstrap/lib/ListGroup";
import Item from "./Item";
import MODALS from "../../../constants/modals";
import { workflowTypes } from "../../../constants/workflowTypes";

interface IWorkflowsProps {
  workflowId: string;
  siteId?: string;
}

const Workflows: React.FunctionComponent<RouteComponentProps<IWorkflowsProps>> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { workflowId } = useParams<{
    workflowId: string;
  }>();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Workflow>();

  const onSubmitCallback = (value = "1") => {
    const values = {
      _id: workflowId,
      name: value,
    };
    axios.put(`/api/workflows/${workflowId}`, values).catch((err) => {
      console.log("err:", err);
    });
  };

  const onDelete = () => {
    axios
      .delete(`/api/workflows/${workflowId}`)
      .then(() => {
        history.push(`/workflows`);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !data) {
      return;
    }

    let reordered = Array.from(data.steps);

    const [removed] = reordered.splice(result.source.index, 1);

    reordered.splice(result.destination.index, 0, removed);

    reordered = reordered.map((step, index) => ({ ...step, order: index }));

    setData((d) => ({ ...data, steps: reordered }));

    axios
      .put(`/api/workflows/${workflowId}/reorder`, {
        steps: reordered.map((step) => ({ _id: step._id, order: step.order })),
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onItemToggle = (item: WorkflowSteps) => {
    axios
      .put<Workflow>(`/api/workflows/${workflowId}/step/${item._id}/toggle`, {
        enabled: !item.enabled,
      })
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onItemDelete = (item: WorkflowSteps) => {
    axios
      .delete<Workflow>(`/api/workflows/${workflowId}/step/${item._id}`)
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onChainedWorkflowAdd = () => {
    axios
      .post<IChainedWorkflow>(`/api/workflows/${workflowId}/chained`)
      .then((result) => {
        setData((e) => ({
          ...(e ? e : result.data),
          type: result.data.type,
          workflows: result.data.workflows,
        }));
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const loadData = () => {
    setLoading(true);
    axios
      .get<Workflow>(`/api/workflows/${workflowId}`)
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  };

  useEffect(() => {
    loadData();
  }, [workflowId]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <div className="d-flex align-items-center">
              <i className="fas fa-random mt-1" />
              <EditableText value={data ? data.name : ""} onSubmit={onSubmitCallback} />
            </div>
          </>
        }
        button={
          <ActionItems
            elements={[
              {
                icon: "warehouse",
                tooltip: t("buttons.sites"),
                type: ActionItemTypes.Button,
                click: () =>
                  dispatch(
                    featuresOpenModal({
                      type: MODALS.WorkflowSites,
                      workflowId: match.params.workflowId,
                    })
                  ),
              },
              // {
              //   icon: "link",
              //   tooltip: t("buttons.addChainedWorkflow"),
              //   type: ActionItemTypes.Button,
              //   click: () => onChainedWorkflowAdd(),
              // },
              {
                icon: "copy",
                tooltip: t("buttons.duplicate"),
                type: ActionItemTypes.Button,
                click: () => {
                  dispatch(
                    featuresOpenModal({
                      type: MODALS.WorkflowDuplicate,
                      workflowId: match.params.workflowId,
                      workflowName: data ? data.name : "",
                      callback: (workflow: string) => {
                        history.push(`/workflows/${workflow}`);
                      },
                    })
                  );
                },
              },
              {
                icon: "trash",
                tooltip: t("buttons.delete"),
                type: ActionItemTypes.Button,
                click: () =>
                  dispatch(
                    openModal(APPROVE_DIALOG_MODAL, {
                      title: t("modalTitles.approveDialog"),
                      message: t("messages.approveWorkflowDelete", { name: data && data.name }),
                      onSuccessCallback: onDelete,
                    })
                  ),
              },
            ]}
            count={0}
            trigger={Triggers.Click}
            icon={<i className="fas fa-cog" />}
            link={false}
          />
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="pb-2">
              <CardHeader className="border-0"></CardHeader>
              <Container>
                <ListGroup>
                  {isLoading ? (
                    <LoadingList count={3} />
                  ) : (
                    <>
                      {data && data.steps.length ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="workflow">
                            {(provided, droppableSnapshot) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {data.steps.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item._id}
                                      draggableId={`item${item._id}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className="pb-1"
                                        >
                                          <Item
                                            dragHandleProps={provided.dragHandleProps}
                                            workflowId={match.params.workflowId}
                                            siteId={match.params.siteId}
                                            item={item}
                                            onSuccess={(workflow: any) => {
                                              setData(workflow);
                                            }}
                                            onToggle={onItemToggle}
                                            onDelete={onItemDelete}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <Row>
                          <Col style={{ textAlign: "center" }}>
                            No steps found, start by adding them
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  <ListGroupItem
                    style={{ border: 0, marginTop: 16 }}
                    action
                    id="tooltip443412080"
                    onClick={() =>
                      dispatch(
                        featuresOpenModal({
                          type: MODALS.WorkflowAddStep,
                          workflowId: match.params.workflowId,
                          // onSuccess: (workflow: Workflow) => {
                          //   setData(workflow)
                          // }
                        })
                      )
                    }
                  >
                    <Container fluid>
                      <Row className="align-items-center justify-content-center">
                        <Col xs="auto">
                          <i className="fas fa-plus-circle" />
                        </Col>
                        <Col xs="auto">{t("buttons.add")}</Col>
                      </Row>
                    </Container>
                  </ListGroupItem>
                  <UncontrolledTooltip delay={0} target="tooltip443412080">
                    {t("tooltips.addNewWorkflowStep")}
                  </UncontrolledTooltip>
                </ListGroup>
              </Container>
            </Card>
          </div>
        </Row>
        {data && data.type === workflowTypes.Chained ? (
          <>
            {data.workflows
              ? data.workflows.map((child) => {
                  return (
                    <ChildWorkflow
                      key={child._id}
                      workflowId={child.workflow}
                      siteId={match.params.siteId}
                    />
                  );
                })
              : null}
          </>
        ) : null}
      </Container>
    </>
  );
};

const ChildWorkflow: React.FunctionComponent<IWorkflowsProps> = ({ workflowId, siteId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IChildWorkflow>();

  const onSubmitCallback = (value = "1") => {
    const values = {
      _id: workflowId,
      name: value,
    };
    axios.put(`/api/workflows/${workflowId}`, values).catch((err) => {
      console.log("err:", err);
    });
  };

  const onDelete = () => {
    axios
      .delete(`/api/workflows/${workflowId}`)
      .then(() => {
        // history.push(`/workflows`);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !data) {
      return;
    }

    let reordered = Array.from(data.steps);

    const [removed] = reordered.splice(result.source.index, 1);

    reordered.splice(result.destination.index, 0, removed);

    reordered = reordered.map((step, index) => ({ ...step, order: index }));

    setData((d) => ({ ...data, steps: reordered }));

    axios
      .put(`/api/workflows/${workflowId}/reorder`, {
        steps: reordered.map((step) => ({ _id: step._id, order: step.order })),
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onItemToggle = (item: WorkflowSteps) => {
    axios
      .put<IChildWorkflow>(`/api/workflows/${workflowId}/step/${item._id}/toggle`, {
        enabled: !item.enabled,
      })
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onItemDelete = (item: WorkflowSteps) => {
    axios
      .delete<IChildWorkflow>(`/api/workflows/${workflowId}/step/${item._id}`)
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const loadData = () => {
    setLoading(true);
    axios
      .get<IChildWorkflow>(`/api/workflows/${workflowId}`)
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  };

  useEffect(() => {
    loadData();
  }, [workflowId]);

  return (
    <Card className="pb-2">
      <CardHeader className="border-0"></CardHeader>
      <Container>
        <ListGroup>
          {isLoading ? (
            <LoadingList count={3} />
          ) : (
            <>
              {data && data.steps.length ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="workflow">
                    {(provided, droppableSnapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {data.steps.map((item, index) => {
                          return (
                            <Draggable key={item._id} draggableId={`item${item._id}`} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="pb-1"
                                >
                                  <Item
                                    dragHandleProps={provided.dragHandleProps}
                                    workflowId={workflowId}
                                    siteId={siteId}
                                    item={item}
                                    onSuccess={(workflow: any) => {
                                      setData(workflow);
                                    }}
                                    onToggle={onItemToggle}
                                    onDelete={onItemDelete}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Row>
                  <Col style={{ textAlign: "center" }}>No steps found, start by adding them</Col>
                </Row>
              )}
            </>
          )}

          <ListGroupItem
            style={{ border: 0, marginTop: 16 }}
            action
            id="tooltip443412080"
            onClick={() =>
              dispatch(
                openModal(WORKFLOW_ADD_STEP, {
                  workflowId: workflowId,
                  siteId: siteId,
                  onSuccess: (workflow: IChildWorkflow) => {
                    setData(workflow);
                  },
                })
              )
            }
          >
            <Container fluid>
              <Row className="align-items-center justify-content-center">
                <Col xs="auto">
                  <i className="fas fa-plus-circle" />
                </Col>
                <Col xs="auto">{t("buttons.add")}</Col>
              </Row>
            </Container>
          </ListGroupItem>
          <UncontrolledTooltip delay={0} target="tooltip443412080">
            {t("tooltips.addNewWorkflowStep")}
          </UncontrolledTooltip>
        </ListGroup>
      </Container>
    </Card>
  );
};

export default Workflows;
