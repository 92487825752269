import React, { useEffect } from "react";

// constnts
import errorCodes from "../constants/errorCodes";

// utils
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

export default function ApiErrorProvider(props) {
  const { t } = useTranslation();

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error.response.data && error.response.data.messageCode) {
          const messageCode = error.response.data.messageCode;
          if (errorCodes[messageCode]) {
            toast.error(t(errorCodes[messageCode]));
          } else {
            toast.error(t(errorCodes[0]));
          }
        } else {
          toast.error(t(errorCodes[0]));
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return <>{props.children}</>;
}
