import React, { useState } from "react";

// utils
import _ from "lodash";
import { IPagination } from "../types/PaginationData";

// reactstrap components
import { Pagination, PaginationItem, PaginationLink, Input } from "reactstrap";
import { useEffect } from "react";

const LEFT_PAGE = "<..";
const RIGHT_PAGE = "..>";

const pageNeighbours = 2;

const totalNumbers = pageNeighbours * 2 + 3;

interface IProps {
  data: IPagination,
  onPageChange: (value: number) => void,
  onLimitChange: (value: number) => void,
  listingCount?: number[],  
}

export default function CustomPagination({ data, onPageChange, onLimitChange, listingCount = [10, 20, 50, 100, 200] } : IProps) {

  const [range, setRange] = useState<(string | number)[]>([]);
  const [listing, setListing] = useState<number>(data.limit);

  const fromRow = (data.page-1)*data.limit + 1;
  const [toRow, setToRow] = useState(data.totalDocs);
  
  useEffect(() => {
    let tmp = [];

    if((data.limit*data.page) > data.totalDocs){
      setToRow((data.page-1)*data.limit + (data.totalDocs - (data.page-1)*data.limit));
    }else{
      setToRow((data.page-1)*data.limit + data.limit);
    }

    if (data.totalPages > totalNumbers) {
      const startPage = Math.max(2, data.page - pageNeighbours);
      const endPage = Math.min(data.totalPages - 1, data.page + pageNeighbours);

      let pages: (string | number)[] = _.range(startPage, endPage + 1);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = data.totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = _.range(startPage - spillOffset, startPage);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = _.range(endPage + 1, endPage + spillOffset + 1);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      tmp = [1, ...pages, data.totalPages];
    } else {
      tmp = _.range(1, data.totalPages + 1);
    }

    setRange(tmp);
  }, [data]);

  return (
    <nav aria-label="..." className="d-flex flex-wrap align-items-baseline">
      <div className="d-flex align-items-baseline">
        <label className="text-sm">
          Show 
        </label>
        <Input type="select" className="w-auto mr-2 ml-2 p-0 h-100" value={listing}
         onChange={(e) => {
           setListing(Number(e.target.value));
           onLimitChange(Number(e.target.value));
         }}>
          {listingCount.map((listing, index) => (
            <option key={index} value={listing}>
              {listing}
            </option>
          ))}
        </Input>
        <label className="text-sm">
          entries. Showing rows from {fromRow} to {toRow} of {data.totalDocs}
        </label>
      </div>

      <Pagination
        className="pagination ml-auto mb-0"
        listClassName="ml-auto mb-0"
      >
      <PaginationItem className={data.hasPrevPage ? undefined : "disabled"}>
          <PaginationLink href="#" onClick={() => onPageChange(data.prevPage ? data.prevPage : 1)} tabIndex={-1}>
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
      </PaginationItem>
        {range.map((page, index) => {
          return (
            <PaginationItem
              key={`paginationItem${index}`}
              className={data.page === page ? "active" : ""}
            >
              <PaginationLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  switch (page) {
                    case LEFT_PAGE:
                      onPageChange(+range[index + 1] - 1);
                      break;
                    case RIGHT_PAGE:
                      onPageChange(+range[index - 1] + 1);
                      break;
                    default:
                      onPageChange(Number(page));
                      break;
                  }
                }}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem className={data.hasNextPage ? undefined : "disabled"}>
          <PaginationLink href="#" onClick={() => onPageChange(data.nextPage ? data.nextPage : 1)}>
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
}
