import { t } from "../helpers/common";

export enum orientations {
  Any = "any",
  Portrait = "portrait",
  Ladscape = "landscape",
}

interface cameraOrientation {
  value: orientations;
  translationString: string;
}

export const LIST: cameraOrientation[] = [
  {
    value: orientations.Any,
    translationString: t("constants.cameraOrientation.any", "Any"),
  },
  {
    value: orientations.Portrait,
    translationString: t("constants.cameraOrientation.portrait", "Portrait"),
  },
  {
    value: orientations.Ladscape,
    translationString: t("constants.cameraOrientation.landscape", "Landscape"),
  },
];
