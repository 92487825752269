import React, { useEffect } from "react";

// constants
import { LOCAL_USER_MODAL } from "../../../modals/types";
import * as scopes from "../../../actions/scopes";

// utils
import { useTranslation } from "react-i18next";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../../actions/users";
import { openModal } from "../../../actions/modals";

// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CustomPagination from "../../../components/CustomPagination";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// parts
import UserRow from "./UserRow";

export default function Users(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      getUsers({
        page: users.paginationData.page,
        limit: users.paginationData.limit,
      })
    );
  }, [
    users.paginationData.timestamp,
    users.paginationData.page,
    users.paginationData.limit,
  ]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-users mr-1" />
            {t("pages.users")}
          </>
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={users.isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        id="tooltip443412080"
                        onClick={() => dispatch(openModal(LOCAL_USER_MODAL))}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-plus" />
                        </span>
                        <span className="btn-inner--text">
                          {t("buttons.create")}
                        </span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip443412080">
                        {t("tooltips.createUser")}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-hover"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: 1 }}></th>
                      <th>{t("fields.fullName")}</th>
                      <th style={{ width: 1 }}>{t("fields.roles")}</th>
                      <th style={{ width: 1 }}>{t("fields.created")}</th>
                      <th style={{ width: 1 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {users.docs.map((user, index) => (
                      <tr key={index}>
                        <UserRow user={user} />
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <CustomPagination
                    data={users.paginationData}
                    scope={scopes.USERS}
                  />
                </CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
