import React, { useEffect, useState } from "react";
import { MODULES } from "../constants/modules";
import { WidgetTypes } from "../constants/widgetsSiteDashboard";

import { closeModalById } from "../actions/modals";

import { useTranslation } from "react-i18next";
import { openModal } from "../actions/modals";
import { useDispatch, useSelector } from "react-redux";
import * as WIDGETS from "../constants/widgetsSiteDashboard";

import axios from "axios";

// components
import { Modal, Button, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { siteRoutes } from "../helpers/apiRoutes";

interface IUser {
  modules: [{ value: MODULES }];
}

interface IProps {
  modalId: string;
  siteId: string;
  onSubmitCallback(): void;
}

export default function AddWidgetModal(props: IProps) {
  const { modalId, siteId, onSubmitCallback } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  // @ts-ignore TODO: Fix redux state for ts
  const user: IUser = useSelector((state) => state.auth.user);

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  const addWidget = (type: WidgetTypes) => {
    axios.post(siteRoutes.post.widget(siteId), { type }).then((res) => {
      onSubmitCallback();
      close();
    });
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.addWidget")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <ListGroup>
          {WIDGETS.LIST.filter((widget) =>
            user.modules.some((module) => widget.allowedModules.includes(module.value))
          ).map((widget) => (
            <ListGroupItem
              action
              onClick={() => {
                if (widget.modal) {
                  dispatch(
                    openModal(widget.modal, {
                      siteId,
                      onSubmitCallback,
                    })
                  );
                } else {
                  addWidget(widget.value);
                }
              }}
            >
              <Row className="align-items-center">
                <Col className=" col-auto">{t(widget.translationString)}</Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
