export const siteRoutes = {
  post: {
    widget: (siteId: string) => `/api/sites/${siteId}/widgets`,
    workflowCount: (siteId: string) => `/api/sites/${siteId}/widgets/workflow`,
    workflow: (siteId: string) => `/api/sites/${siteId}/addWorkflow`,
  },
  get: {
    workflows: (siteId: string) => `/api/sites/${siteId}/workflows`,
    workflowsAll: (siteId: string) => `/api/sites/${siteId}/workflows/all`,
    tasks: (siteId: string) => `/api/sites/${siteId}/tasks`,
    widgets: (siteId: string) => `/api/sites/${siteId}/widgets`,
    sites: (page: number, limit: number) => `/api/sites?page=${page}&limit=${limit}`,
    site: (siteId: string) => `/api/sites/${siteId}`,
    events: (siteId: string) => `/api/events/site/${siteId}`,
    exportEvents: (siteId: string) => `/api/events/site/${siteId}/export`,
  },
  delete: {
    workflow: (siteId: string) => `/api/sites/${siteId}/removeWorkflow`,
    widget: (siteId: string, widgetId: string) => `/api/sites/${siteId}/widgets/${widgetId}`,
  },
};

export const workflowRoutes = {
  post: {},
  get: {
    workflow: (workflowId: string) => `/api/workflows/${workflowId}`,
    workflows: "/api/workflows/all",
    workflowStep: (workflowId: string, stepId: string) =>
      `/api/workflows/${workflowId}/step/${stepId}`,
    executionCount: (siteId: string, workflowId: string) =>
      `/api/widgets/site/${siteId}/workflow/${workflowId}/executionCount`,
  },
  delete: {},
};

export const visitorRoutes = {
  get: {
    visitorsToday: (siteId: string) => `/api/widgets/site/${siteId}/visitors/today`,
    visitorsWeek: (siteId: string) => `/api/widgets/site/${siteId}/visitors/week`,
  },
};

export const transportRoutes = {
  get: {
    transportsToday: (siteId: string) => `/api/widgets/site/${siteId}/transports/today`,
    transportsWeek: (siteId: string) => `/api/widgets/site/${siteId}/transports/week`,
  },
};

export const usersRoutes = {
  post: {
    widget: "/api/workspace/widgets",
  },
  get: {
    widget: "/api/workspace/widgets",
    actions: "/api/workspace/widgets/userActions",
  },
  delete: {
    widget: (widgetId: string) => `/api/workspace/widgets/${widgetId}`,
  },
};
