import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { formatDatetime } from "../helpers/date";

export default function TimeAgo(props) {
  const { time } = props;

  const { i18n } = useTranslation();

  const [locale, setLocale] = useState(i18n.language);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <>
      {moment(time).diff(moment(), "days") > 30
        ? formatDatetime(time)
        : moment(time)
            .locale(locale)
            .fromNow()}
    </>
  );
}
