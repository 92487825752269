import React, { useEffect } from "react";

// constants
import { WORKSPACE_SETTINGS_MODAL } from "../../../modals/types";
import * as scopes from "../../../actions/scopes";

// utils
import { useTranslation } from "react-i18next";
import { formatDate, objectIdToTimestamp } from "../../../helpers/date";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getWorkspaces } from "../../../actions/workspaces";
import { openModal } from "../../../actions/modals";

// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CustomPagination from "../../../components/CustomPagination";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function Workspaces(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const workspaces = useSelector((state) => state.workspaces);

  useEffect(() => {
    dispatch(
      getWorkspaces({
        page: workspaces.paginationData.page,
        limit: workspaces.paginationData.limit,
      })
    );
  }, [
    workspaces.paginationData.timestamp,
    workspaces.paginationData.page,
    workspaces.paginationData.limit,
  ]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-object-group"></i>
            {t("pages.workspaces")}
          </>
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={workspaces.isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      {/* <Button
                        className="btn-round btn-icon"
                        color="primary"
                        id="tooltip443412080"
                        onClick={
                          () =>
                          // dispatch(
                          //   openModal(LOCAL_USER_MODAL, {
                          //     data: {
                          //       role: "admin",
                          //     },
                          //   })
                          // )
                        }
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-plus" />
                        </span>
                        <span className="btn-inner--text">
                          {t("buttons.create")}
                        </span>
                      </Button> */}
                      {/* <UncontrolledTooltip delay={0} target="tooltip443412080">
                        {t("tooltips.createUser")}
                      </UncontrolledTooltip> */}
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-hover"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th>{t("fields.name")}</th>
                      <th style={{ width: 1 }}>{t("fields.userCount")}</th>
                      <th style={{ width: 1 }}>{t("fields.created")}</th>
                      <th style={{ width: 1 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {workspaces.docs.map((workspace, index) => (
                      <tr key={index}>
                        <td>
                          <b>{workspace.name}</b>
                        </td>
                        <td>{workspace.tid}</td>
                        <td>
                          {formatDate(objectIdToTimestamp(workspace._id))}
                        </td>
                        <td className="table-action">
                          <button
                            id={`workspaceTooltip${index}`}
                            type="button"
                            className="btn btn-link table-action"
                            onClick={() =>
                              dispatch(
                                openModal(WORKSPACE_SETTINGS_MODAL, {
                                  data: workspace,
                                })
                              )
                            }
                          >
                            <i class="fas fa-edit" />
                          </button>
                          <UncontrolledTooltip
                            delay={0}
                            target={`workspaceTooltip${index}`}
                          >
                            {t("tooltips.editWorkspace")}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <CustomPagination
                    data={workspaces.paginationData}
                    scope={scopes.WORKSPACES}
                  />
                </CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
