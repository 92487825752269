import * as yup from "yup";

const geolocationSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  geolocationCollection: yup.string().required(),
  description: yup.string().max(2500),
  check: yup.string().required(),
  enableLocation: yup.bool(),
  enabled: yup.bool(),
});

export default geolocationSchema;
