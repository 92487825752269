import React from "react";
import PropTypes from "prop-types";

// components
import { Container, Row, Col } from "reactstrap";

class SimpleHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4 w-100 ml-0">
                <Col className="pl-0">
                  <h6 className="fullcalendar-title h2 text-default d-inline-block mb-0 ml-0 w-100">
                    {this.props.name}
                  </h6>
                </Col>
                {this.props.button}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

SimpleHeader.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  parentName: PropTypes.string,
};

export default SimpleHeader;
