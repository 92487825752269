import React from "react";

import SimpleHeader from "../components/Headers/SimpleHeader";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, CardBody, Button, Label, Badge } from "reactstrap";

import Microsoft from "../assets/img/icons/common/microsoft.svg";
import * as USER_TYPES from "../constants/deprecatedUserTypes";

import { PROFILE_PASSWORD_CHANGE_MODAL } from "../modals/types";

import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../actions/modals";

interface IUser {
  _id: string;
  name: string;
  roles: [
    {
      _id: string;
      name: string;
    }
  ];
  username: string;
  workspace: {
    type: string;
  };
  type: string;
}

export default function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // @ts-ignore
  const user: IUser = useSelector((state) => state.auth.user);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-user mr-1" />
            {t("pages.profile")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody className="d-flex">
                <div className="w-100">
                  <Row>
                    <Col className="col-md-2">
                      <p className="text-muted">{t("fields.email")}</p>
                    </Col>
                    <Col className="d-flex">
                      {user.type === USER_TYPES.AZURE ? (
                        <img src={Microsoft} alt="Microsoft logo" className="mb-3" />
                      ) : null}
                      <p>{user.username}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-2">
                      <p className="text-muted">{t("fields.username")}</p>
                    </Col>
                    <Col>
                      <p>{user.username}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-2">
                      <p className="text-muted">{t("fields.name")}</p>
                    </Col>
                    <Col>
                      <p className="text-truncate">{user.name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-2">
                      <p className="text-muted">{t("fields.roles")}</p>
                    </Col>
                    <Col>
                      {user.roles.map((role) => (
                        <Badge color="primary" key={role._id}>
                          {role.name}
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                </div>
                {user.type !== USER_TYPES.AZURE ? (
                  <Button
                    className="btn-sm align-self-end ml-auto p-2"
                    onClick={() => {
                      dispatch(
                        openModal(PROFILE_PASSWORD_CHANGE_MODAL, {
                          data: {
                            _id: user._id,
                          },
                        })
                      );
                    }}
                  >
                    {t("tooltips.changePassword")}
                  </Button>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
