import { t } from "../helpers/common";

import { ROLE_PERMISSIONS } from "./rolePermissions";

export enum MODULES {
  eventRegistry = "event_registry",
  transportRegistry = "transport_registry",
  visitorRegistry = "visitor_registry",
  Calendar = "calendar",
  Workflows = "workflows",
}

interface IModules {
  value: MODULES;
  translationString: string;
  maxPermission: number;
}

export const LIST: IModules[] = [
  {
    value: MODULES.eventRegistry,
    translationString: t("modules.eventRegistry", "Event registry"),
    maxPermission:
      ROLE_PERMISSIONS.READ |
      ROLE_PERMISSIONS.WRITE |
      ROLE_PERMISSIONS.UPDATE |
      ROLE_PERMISSIONS.DELETE,
  },
  {
    value: MODULES.transportRegistry,
    translationString: t("modules.transportRegistry", "Transport registry"),
    maxPermission:
      ROLE_PERMISSIONS.READ |
      ROLE_PERMISSIONS.WRITE |
      ROLE_PERMISSIONS.UPDATE |
      ROLE_PERMISSIONS.DELETE,
  },
  {
    value: MODULES.visitorRegistry,
    translationString: t("modules.visitorRegistry", "Visitor registry"),
    maxPermission:
      ROLE_PERMISSIONS.READ |
      ROLE_PERMISSIONS.WRITE |
      ROLE_PERMISSIONS.UPDATE |
      ROLE_PERMISSIONS.DELETE,
  },
  {
    value: MODULES.Calendar,
    translationString: t("modules.calendar", "Calendar"),
    maxPermission:
      ROLE_PERMISSIONS.READ |
      ROLE_PERMISSIONS.WRITE |
      ROLE_PERMISSIONS.UPDATE |
      ROLE_PERMISSIONS.DELETE,
  },
  {
    value: MODULES.Workflows,
    translationString: t("modules.workflows", "Workflows"),
    maxPermission:
      ROLE_PERMISSIONS.READ |
      ROLE_PERMISSIONS.WRITE |
      ROLE_PERMISSIONS.UPDATE |
      ROLE_PERMISSIONS.DELETE,
  },
];

//Deprecated, use enum instead
export const EVENT_REGISTRY = "event_registry";
export const TRANSPORT_REGISTRY = "transport_registry";
export const VISITOR_REGISTRY = "visitor_registry";
export const CALENDAR = "calendar";
export const WORKFLOWS = "workflows";

export const MODULE_LIST = [
  {
    value: EVENT_REGISTRY,
    translationString: t("modules.eventRegistry", "Event registry"),
  },
  {
    value: TRANSPORT_REGISTRY,
    translationString: t("modules.transportRegistry", "Transport registry"),
  },
  {
    value: VISITOR_REGISTRY,
    translationString: t("modules.visitorRegistry", "Visitor registry"),
  },
  {
    value: CALENDAR,
    translationString: t("modules.calendar", "Calendar"),
  },
  {
    value: WORKFLOWS,
    translationString: t("modules.workflows", "Workflows"),
  },
];
