import React, { useEffect, useState } from "react";

// types
import { IPaginationDocs } from "../../../types/PaginationData";
import { IWorkflowData } from "../../../types/WorkflowData";

// utils
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";

// components
import LoadingOverlay from "../../../components/LoadingOverlay";
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  Badge,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";

// Parts
import Search from "../../../components/Inputs/Search";
import ReportHeader from "../../../components/Headers/ReportHeader";
import moment from "moment";
import { IExecution } from "../../../types/WorkflowExecution";
import {
  WorkflowExecutionStatus,
  WORKFLOW_EXECUTION_STATUSES,
} from "../../../constants/workflowExecutionStatuses";
import ExecutionSteps from "./steps";

export default function WorkflowData() {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const [data, setData] = useState<IPaginationDocs<IWorkflowData>>({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 20,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
  });

  const onTableChange = (type: any, { page, sizePerPage }: any) => {
    switch (type) {
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  useEffect(() => {
    let params: {
      page: number;
      limit: number;
      search?: string;
    } = {
      page: data.page,
      limit: data.limit,
    };

    if (search) {
      params.search = search;
    }

    setLoading(true);
    axios
      .get(`/api/executions/workflows/site/${siteId}`, {
        params: params,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  }, [siteId, data.limit, data.page, search]);

  return (
    <>
      <ReportHeader
        name={
          <>
            <i className="fas fa-random mr-1" />
            {t("pages.siteWorkflows")}
          </>
        }
        // onPdfExport={onPdfExport}
        // pdfIsLoading={pdfIsLoading}
      />

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <LoadingOverlay isLoading={isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col sm="auto">
                      <Search
                        onSearch={(text) => {
                          setSearch(text);
                        }}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                  columns={[
                    {
                      dataField: "workflow",
                      text: t("fields.workflow"),
                      formatter: (workflow) => {
                        return workflow ? workflow.name : "-";
                      },
                    },
                    {
                      dataField: "startedAt",
                      text: t("fields.startedAt"),
                      formatter: (startedAt) => {
                        if (!startedAt) {
                          return null;
                        }
                        return moment(startedAt).format("L LT");
                      },
                    },
                    {
                      dataField: "finishedAt",
                      text: t("fields.finishedAt"),
                      formatter: (finishedAt) => {
                        if (!finishedAt) {
                          return null;
                        }
                        return moment(finishedAt).format("L LT");
                      },
                    },
                    {
                      dataField: "user",
                      text: t("fields.user"),
                      formatter: (user) => {
                        if (!user) {
                          return "-";
                        }

                        return user.name
                          ? user.name
                          : `${user.firstName} ${user.lastName}`;
                      },
                    },
                    {
                      isDummyField: true,
                      dataField: "duration",
                      text: t("fields.duration"),
                      formatter: (duration, row: IWorkflowData) => {
                        const tmp = moment.duration(
                          moment(row.finishedAt).diff(moment(row.startedAt))
                        );

                        if (tmp.hours() > 0) {
                          return moment(
                            moment.utc(tmp.asMilliseconds())
                          ).format("hh:mm:ss");
                        } else {
                          return moment(
                            moment.utc(tmp.asMilliseconds())
                          ).format("mm:ss");
                        }
                      },
                    },
                    {
                      dataField: "status",
                      text: t("fields.status"),
                      formatter: (status: WorkflowExecutionStatus) => {
                        return (
                          <Badge
                            color={WORKFLOW_EXECUTION_STATUSES[status].color}
                          >
                            {WORKFLOW_EXECUTION_STATUSES[status].label}
                          </Badge>
                        );
                      },
                    },
                  ]}
                  expandRow={{
                    renderer: (row: IExecution) => {
                      return <ExecutionSteps execution={row} />;
                    },
                    showExpandColumn: true,
                    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                      if (isAnyExpands) {
                        return <i className="fas fa-chevron-up" />;
                      }
                      return <i className="fas fa-chevron-down" />;
                    },
                    expandColumnRenderer: ({ expanded }) => {
                      if (expanded) {
                        return <i className="fas fa-chevron-up" />;
                      }
                      return <i className="fas fa-chevron-down" />;
                    },
                  }}
                />
                <CardFooter className="py-4"></CardFooter>
              </LoadingOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
