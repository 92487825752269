import React from "react";
import { FormField, fields as fieldTypes } from "../../../../../constants/formFields";

// utils
import { useTranslation } from "react-i18next";
import _ from "lodash";

// components
import BootstrapTable from "react-bootstrap-table-next";

interface Props {
  fields: FormField[];
}

export default function FormStepData({ fields }: Props) {
  const { t } = useTranslation();

  return (
    <BootstrapTable
      keyField="_id"
      data={_.sortBy(fields, "order")}
      columns={[
        {
          dataField: "name",
          text: t("fields.field"),
        },
        {
          dataField: "value",
          text: t("fields.value"),
          formatter: (value, row: FormField) => {
            switch (row.type) {
              case fieldTypes.Checkbox:
                if (value) {
                  return <i className="fas fa-check" />;
                } else {
                  return "";
                }
              default:
                return value;
            }
          },
        },
      ]}
    />
  );
}
