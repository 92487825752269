import * as yup from "yup";

import { orientations } from "../../constants/cameraOrientations";

const cameraSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  description: yup.string().max(2500),
  enableLocation: yup.bool(),
  enabled: yup.bool(),
  min: yup.number().max(yup.ref("max"), "Min value can't be larger than max"),
  max: yup
    .number()
    .min(yup.ref("min"), "Max value can't smaller than min")
    .max(10),
  orientation: yup
    .string()
    .required()
    .oneOf(Object.values(orientations)),
});

export default cameraSchema;
