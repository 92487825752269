import { some } from "lodash";
import React from "react";

import { useSelector } from "react-redux";
import { MODULES } from "../constants/modules";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";
import { IUser } from "../types/User";

interface IPermissionGuardProps {
  module: MODULES | MODULES[];
  permission: ROLE_PERMISSIONS;
  children: (options: { hasAccess: boolean }) => React.ReactElement;
}

function hasPermission(
  requiredPermission: ROLE_PERMISSIONS,
  permission: number,
  requiredModule: MODULES[],
  module: MODULES
) {
  return requiredModule.includes(module) && (permission | requiredPermission) === permission;
}

const PermissionGuard: React.FunctionComponent<IPermissionGuardProps> = ({
  module,
  permission,
  children,
}) => {
  // @ts-ignore
  const user: IUser = useSelector((state) => state.auth.user);

  return children({
    hasAccess: some(
      user.roles.map((role) => role.modules).reduce((a, b) => a.concat(b), []),
      (m) =>
        hasPermission(permission, m.permission, Array.isArray(module) ? module : [module], m.value)
    ),
  });
};

export default PermissionGuard;
