import { OPEN_MODAL, CLOSE_MODAL, CLOSE_MODAL_BY_ID } from "./types";
import uuidv4 from "uuid";

export const openModal = (modalName, modalProps) => {
  return {
    type: OPEN_MODAL,
    payload: {
      modalId: uuidv4(),
      modalName,
      modalProps
    }
  };
};

export const closeModal = name => {
  return {
    type: CLOSE_MODAL,
    name
  };
};

export const closeModalById = id => {
  return {
    type: CLOSE_MODAL_BY_ID,
    payload: id
  };
};
