import { SET_ROLES, APPEND_ROLE, REPLACE_ROLE, REMOVE_ROLE } from "../types";

export const setRoles = (roles) => {
  return {
    type: SET_ROLES,
    roles,
  };
};

export const appendRole = (role) => {
  return {
    type: APPEND_ROLE,
    payload: role,
  };
};

export const replaceRole = (role) => {
  return {
    type: REPLACE_ROLE,
    payload: role,
  };
};

export const removeRole = (_id) => {
  return {
    type: REMOVE_ROLE,
    payload: _id,
  };
};
