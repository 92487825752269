import React, { useState, useEffect } from "react";

// constants & types
import { RouteComponentProps } from "react-router-dom";
import { SEVERITIES } from "../../constants/severities";
import { ISite } from "../../types/Site";
import { IPaginationDocs } from "../../types/PaginationData";
import { IEvent } from "../../types/Event";

// utils
import axios from "axios";
import { useTranslation } from "react-i18next";
import qs from "qs";
import moment from "moment";
import _ from "lodash";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { safeString } from "../../helpers/text";
import { formatDate, formatDatetime } from "../../helpers/date";
import { siteRoutes } from "../../helpers/apiRoutes";

//Redux
import { useDispatch } from "react-redux";
import { EVENT_MODAL } from "../../modals/types";
import { openModal } from "../../actions/modals";

// components
import paginationFactory from "react-bootstrap-table2-paginator";

import ReportHeader from "../../components/Headers/ReportHeader";
import LoadingOverlay from "../../components/LoadingOverlay";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Parts
import Filters from "./Filters";

// exports
import EventsPdf from "../../exports/eventsPdf";
import BootstrapTable from "react-bootstrap-table-next";

interface IEventsParams {
  siteId: string;
}

interface IEventsFilters {
  description?: string;
  dateFrom?: Date;
  dateUntil?: Date;
  eventTypeId?: string;
}

const Events: React.FunctionComponent<RouteComponentProps<IEventsParams>> = ({ match }) => {
  const { siteId } = match.params;

  const { t } = useTranslation();
  // TODO: useAppDispatch
  const dispatch = useDispatch();

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfInProgress, setPdfInProgress] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [data, setData] = useState<IPaginationDocs<IEvent> & { filters?: IEventsFilters }>({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
    sort: { field: "occured", order: "desc" },
  });

  const onTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }: any
  ) => {
    switch (type) {
      case "sort":
        setData((d) => ({ ...d, page: 1, sort: { field: sortField, order: sortOrder } }));
        break;
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  const onPdfExport = () => {
    setPdfInProgress(true);

    axios
      .get<{ site: ISite; events: IEvent[] }>(siteRoutes.get.exportEvents(siteId), {
        params: {
          sort: qs.stringify(data.sort),
          filters: qs.stringify(data.filters),
        },
      })
      .then(async (response) => {
        const asPdf = pdf(EventsPdf(response.data.site, response.data.events));
        const blob = await asPdf.toBlob();
        saveAs(blob, `${formatDate(moment())}${safeString(response.data.site.name)}.pdf`);
        setPdfInProgress(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setPdfInProgress(false);
      });
  };

  const onUpdate = (event: IEvent) => {
    setData((d) => ({ ...d, docs: d.docs.map((doc) => (doc._id === event._id ? event : doc)) }));
  };

  const loadData = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    axios
      .get<IPaginationDocs<IEvent>>(siteRoutes.get.events(siteId), {
        params: {
          page: data.page,
          limit: data.limit,
          sort: qs.stringify(data.sort),
          filters: qs.stringify(data.filters),
        },
      })
      .then((response) => {
        setData((d) => ({ ...d, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (initialized) {
      loadData();
    }
  }, [data.limit, data.page, data.sort, data.filters]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      <ReportHeader
        name={
          <>
            <i className="fas fa-history mr-1" />
            {t("pages.events")}
          </>
        }
        onPdfExport={onPdfExport}
        pdfIsLoading={pdfInProgress}
        filterCount={_.size(data.filters)}
        toggleFilters={() => {
          setFiltersOpen((f) => !f);
        }}
        filtersNode={
          <Filters
            isOpen={filtersOpen}
            onChange={(data) => {
              setData((d) => ({ ...d, page: 1, filters: data }));
            }}
          />
        }
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={loading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        id="tooltip443412080"
                        onClick={() =>
                          dispatch(
                            openModal(EVENT_MODAL, {
                              siteId,
                              onAdd: loadData,
                            })
                          )
                        }
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">{t("buttons.add")}</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip443412080">
                        {t("tooltips.addNewEvent")}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  defaultSorted={[
                    {
                      dataField: "occured",
                      order: "desc",
                    },
                  ]}
                  columns={[
                    {
                      dataField: "eventType",
                      text: "Event type",
                      // @ts-ignore
                      formatter: (eventType) => {
                        if (!eventType) {
                          return null;
                        }

                        const severity = SEVERITIES.find((s) => s.value === eventType.priority);

                        return (
                          <>
                            {severity ? (
                              <i
                                className={`fas fa-circle text-${severity.color}`}
                                style={{ paddingRight: 8 }}
                              ></i>
                            ) : null}
                            {eventType.name}
                          </>
                        );
                      },
                      headerStyle: { width: 200 },
                    },
                    {
                      dataField: "description",
                      text: "Description",
                    },
                    {
                      dataField: "occured",
                      text: "Occured",
                      sort: true,
                      formatter: (finishedAt) => {
                        return formatDatetime(finishedAt);
                      },
                      headerStyle: { width: 160 },
                    },
                  ]}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      dispatch(
                        openModal(EVENT_MODAL, {
                          siteId,
                          data: row,
                          onUpdate,
                          onDelete: loadData,
                        })
                      );
                    },
                  }}
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                />
                <CardFooter className="py-4"></CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Events;
