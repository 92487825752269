import React from "react";

// interfaces
import { FormikErrors, FormikTouched } from "formik";
import { IFormFieldInput } from "../../../../../constants/formFields";

// utils
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

// components
import { Col, Row } from "reactstrap";
import LabelFormGroup from "../../../../../components/Inputs/LabelFormGroup";

interface IInputItemProps {
  values: IFormFieldInput;
  index: number;
  touched: FormikTouched<IFormFieldInput>;
  errors: FormikErrors<IFormFieldInput>;
  setFieldValue: ISetFieldValue;
}

interface ISetFieldValue {
  (field: string, value: any, shouldValidate?: boolean | undefined): void;
}

export default function InputItem({
  index,
  values,
  touched,
  errors,
  setFieldValue,
}: IInputItemProps) {
  const { t } = useTranslation();

  const requiredLabelId = uniqid();

  return (
    <>
      <Row>
        <Col>
          <LabelFormGroup
            label={t("fields.fieldName")}
            value={values.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`fields.${index}.name`, e.target.value);
            }}
            touched={touched ? touched.name : false}
            validationErrors={errors ? errors.name : null}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelFormGroup
            label={t("fields.minLength")}
            type="number"
            min={0}
            value={values.minLength}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`fields.${index}.minLength`, e.target.value);
            }}
            touched={touched ? touched.minLength : false}
            validationErrors={errors ? errors.minLength : null}
          />
        </Col>
        <Col>
          <LabelFormGroup
            label={t("fields.maxLength")}
            type="number"
            min={0}
            value={values.maxLength}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`fields.${index}.maxLength`, e.target.value);
            }}
            touched={touched ? touched.maxLength : false}
            validationErrors={errors ? errors.maxLength : null}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="custom-control custom-control-alternative custom-checkbox">
            <input
              className="custom-control-input"
              id={requiredLabelId}
              type="checkbox"
              checked={values.required}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`fields.${index}.required`, e.target.checked);
              }}
            />
            <label className="custom-control-label" htmlFor={requiredLabelId}>
              <span className="text-muted">{t("fields.required")}</span>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
}
