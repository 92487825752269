import React, { useEffect, useState } from "react";
import classnames from "classnames";

export default function Thumbnail(props) {
  const { value, alt, hoverable } = props;

  // const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (value) {
      if (typeof value !== "string") {
        let reader = new FileReader();

        reader.onloadend = () => {
          // setLoading(false);
          setSrc(reader.result);
        };

        reader.readAsDataURL(value);
      } else if (value !== "") {
        // setLoading(false);
        setSrc(value);
      }
    }
  }, [value]);

  return (
    <div style={{ width: 100 }}>
      <div
        className={classnames({
          hovereffect: hoverable,
        })}
      >
        <img
          style={{ maxWidth: "100%" }}
          src={src}
          alt={alt ? alt : "Image thumbnail"}
        />
        {hoverable ? (
          <div className="overlay">
            <i className="fas fa-eye"></i>
          </div>
        ) : null}
      </div>
    </div>
  );
}
