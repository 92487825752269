import {
  SET_ROLES,
  REPLACE_ROLE,
  APPEND_ROLE,
  REMOVE_ROLE,
} from "../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return action.roles;
    case APPEND_ROLE:
      return [...state, action.payload];
    case REPLACE_ROLE:
      return state.map((role) => {
        return action.payload._id === role._id ? action.payload : role;
      });
    case REMOVE_ROLE:
      return state.filter((item) => item._id !== action.payload);
    default:
      return state;
  }
}
