import React from "react";
import { IQrValue } from "../../../../../types/WorkflowExecution";

// utils
import { useAppDispatch } from "../../../../../hooks/redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// components
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { Button } from "reactstrap";
import { openModal } from "../../../../../features/modals";
import MODALS from "../../../../../constants/modals";
import { ILocationCoords } from "../../../../../types/common";

interface Props {
  values?: IQrValue[];
}

export default function QrStepData({ values }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <BootstrapTable
      keyField="_id"
      data={values ? values : []}
      columns={[
        {
          dataField: "qrCode.name",
          text: t("fields.name"),
        },
        {
          dataField: "scannedAt",
          text: t("fields.scanned"),
          formatter: (scannedAt) => {
            return moment(scannedAt).format("L LT");
          },
        },
        {
          dataField: "location",
          text: "",
          headerFormatter: () => {
            if (!values || !values.find((value) => value.location)) {
              return "";
            }
            return (
              <Button
                className="btn-neutral btn-icon"
                size="sm"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Map,
                      markers: values
                        .filter((value) => value.location)
                        .map((value) => {
                          return {
                            location: value.location as ILocationCoords,
                          };
                        }),
                    })
                  );
                }}
              >
                <i className="fas fa-map-marked-alt"></i>
              </Button>
            );
          },
          formatter: (location) => {
            if (!location) {
              return "";
            }
            return (
              <Button
                className="btn-neutral btn-icon"
                size="sm"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: MODALS.Map,
                      markers: [
                        {
                          location: location,
                        },
                      ],
                    })
                  );
                }}
              >
                <i className="fas fa-map-marked-alt"></i>
              </Button>
            );
          },
          headerStyle: { width: 50 },
        },
      ]}
    />
  );
}
