import React, { useEffect, useState } from "react";

import { IImage } from "../../../../../types/Image";

// utils
import { useTranslation } from "react-i18next";
import _ from "lodash";

// components
import { Button, Col, Row } from "reactstrap";
import Thumbnail from "../../../../../components/Thumbnail";
import BootstrapTable from "react-bootstrap-table-next";
import { useAppDispatch } from "../../../../../hooks/redux";
import moment from "moment";
import MODALS from "../../../../../constants/modals";
import { ILocationCoords } from "../../../../../types/common";
import Lightbox from "react-image-lightbox";
import { openModal } from "../../../../../features/modals";

interface Props {
  images?: IImage[];
}

export default function ImagesStepData({ images }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [lightboxImages, setLightboxImages] = useState<string[]>([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    prepareLightboxImages();
  }, [images]);

  const prepareLightboxImages = async () => {
    setLightboxImages(
      images
        ? images.map((image) => {
            return `${process.env.REACT_APP_API_URL}uploads/${image.original.key}`;
          })
        : []
    );
  };

  return (
    <>
      <Row>
        <Col>
          <BootstrapTable
            keyField="_id"
            data={images ? images : []}
            columns={[
              {
                dataField: "value",
                text: t("fields.image"),
                formatter: (value, row) => {
                  return (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!images) {
                          return;
                        }

                        setImageIndex(images.findIndex((image) => image._id === row._id));
                        setLightboxOpen(true);
                      }}
                    >
                      <div className="avatar" style={{ width: "auto", height: "auto" }}>
                        <Thumbnail
                          hoverable
                          value={
                            row._id
                              ? `${process.env.REACT_APP_API_URL}uploads/${row.thumbnail.key}`
                              : row
                          }
                        />
                      </div>
                    </a>
                  );
                },
              },
              {
                dataField: "capturedAt",
                text: t("fields.taken"),
                formatter: (capturedAt) => {
                  return capturedAt ? moment(capturedAt).format("L LT") : "-";
                },
              },
              {
                dataField: "location",
                text: "",
                headerFormatter: () => {
                  if (!images || !images.find((value) => value.location)) {
                    return "";
                  }
                  return (
                    <Button
                      className="btn-neutral btn-icon"
                      size="sm"
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: MODALS.Map,
                            markers: images
                              .filter((image) => image.location)
                              .map((image) => {
                                return {
                                  location: image.location as ILocationCoords,
                                };
                              }),
                          })
                        );
                      }}
                    >
                      <i className="fas fa-map-marked-alt"></i>
                    </Button>
                  );
                },
                formatter: (location) => {
                  if (!location) {
                    return "";
                  }
                  return (
                    <Button
                      className="btn-neutral btn-icon"
                      size="sm"
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: MODALS.Map,
                            markers: [
                              {
                                location: location,
                              },
                            ],
                          })
                        );
                      }}
                    >
                      <i className="fas fa-map-marked-alt"></i>
                    </Button>
                  );
                },
                headerStyle: { width: 50 },
              },
            ]}
          />
        </Col>
      </Row>
      {lightboxOpen ? (
        <Lightbox
          mainSrc={lightboxImages[imageIndex]}
          nextSrc={lightboxImages[(imageIndex + 1) % lightboxImages.length]}
          prevSrc={lightboxImages[(imageIndex + lightboxImages.length - 1) % lightboxImages.length]}
          onCloseRequest={() => {
            setLightboxOpen(false);
          }}
          onMovePrevRequest={() => {
            setImageIndex((imageIndex + lightboxImages.length - 1) % lightboxImages.length);
          }}
          onMoveNextRequest={() => {
            setImageIndex((imageIndex + 1) % lightboxImages.length);
          }}
        />
      ) : null}
    </>
  );
}
