import React from "react";

import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";

interface ILoadingList {
  count: number;
}

export default function LoadingList({ count }: ILoadingList) {
  return (
    <ListGroup>
      {[...Array(count)].map((value: undefined, index: number) => (
        <ListGroupItem key={index}>
          <Container fluid>
            <Row className="align-items-center">
              <Col xs="auto">
                <i className="fas fa-grip-vertical" style={{ paddingTop: 15, paddingBottom: 15 }} />
              </Col>
              <Col xs="auto">
                <Skeleton circle={true} height={48} width={48} />
              </Col>
              <Col>
                <Skeleton height={24} width={300} />
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
